<template>
  <v-container class="tw-h-full d-flex align-center justify-center">
    <v-card class="tw-p-8 justify-center d-block" color="#34435B">
      <v-text-field
        background-color="#889CBD"
        label="Table"
        prefix="N'"
        v-model="table"
        class=""
        filled
        color="white"
      ></v-text-field>

      <v-card-actions>
        <button
          @click="setTable()"
          class="ma-0 mt-2 pa-2 tw-text-lg mx-auto tw-w-3/4 d-block tw-rounded-sm btn-valider tw-text-black"
        >
          Valider
        </button>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    table: null,
  }),
  methods: {
    setTable() {
      this.$store.dispatch("addTableUser", this.table);
      let path = this.$router.history.current.path.replace("table", "menu");
      this.$router.push(path);
    },
  },
};
</script>

<style scoped>
.btn-valider {
  background-color: #d9d9d9;
}
</style>
