<template>
  <v-container v-if="isLoaded" fluid>
    <v-row>
      <v-col>
        <v-card color="#0D131C">
          <v-img
            :src="
              picture
                ? picture
                : 'https://p.kindpng.com/picc/s/490-4901631_upcoming-events-image-free-clipart-png-download-upcoming.png'
            "
            class="white--text align-end tw-aspect-square"
            max-height="300px"
            contain
          >
          </v-img>
          <v-card-title>
            {{ eventData.shop.name }}
          </v-card-title>
          <v-card-subtitle class="tw-block">
            <v-icon color="primary"> mdi-map-marker </v-icon>
            {{ eventData.shop.address }}
          </v-card-subtitle>

          <v-card-subtitle>
            <v-chip color="green" label> ouvert </v-chip>
            Ferme a {{ getTime(eventData.shop.close) }}
          </v-card-subtitle>
          <!-- <div v-for="item in eventData.events" :key="item.id">
            <v-divider></v-divider> -->
          <!-- <v-expansion-panels class="bg-color pa-0">
      <v-expansion-panel
      class="pa-0"
      >
      <v-expansion-panel-header hide-actions color="#0D131C" class="pa-0">
                    <v-card-text class="pa-1">
                        <v-col cols="12" class="text-h6 pa-0">
                            {{item.name}}
                        </v-col>
                        <v-col cols="12" class="d-flex ma-0 tw-content-center pa-0">
                            <v-col cols="9" >
                                <span class="tw-text-lg tw-block">{{ new Date(item.date) | moment("ddd Do MMM") }}</span>
                                <span class="tw-block">{{item.from}} - {{item.to}}</span>
                            </v-col>
                            <v-col cols="3" class="tw-flex tw-flex-row-reverse my-auto">
                                <v-btn icon @click="changeIcon">
                                    <v-icon v-if="!loading" :color="IconColor" x-large>
                                        {{Icon}}
                                    </v-icon>
                                    <v-progress-circular
                                    v-if="loading"
                                    indeterminate
                                    color="primary"
                                    ></v-progress-circular>
                                </v-btn>
                            </v-col>
                        </v-col>
                    </v-card-text>
                </v-expansion-panel-header>
                <v-expansion-panel-content color="#0D131C" class="pa-1">
                    <v-divider></v-divider>
                    <v-card-text class="d-flex pa-0 ma-0">
                        <v-col cols="6">
                        <v-btn color="primary" block :to="'/events/'+item.id+'/tickets'">
                            Tickets
                        </v-btn>
                        </v-col>
                        <v-col cols="6">
                        <v-btn color="green" block :to="'/events/'+item.id+'/menu'">
                            Menu
                        </v-btn>
                        </v-col>
                    </v-card-text>
                    <v-card-text>
                        <v-col v-if="item.description" cols="12">
                            <h1 class="text-h6"> Description </h1>
                            <span>
                               {{item.description}}
                            </span>
                        </v-col>
                    </v-card-text>
      </v-expansion-panel-content>
      </v-expansion-panel>
                    </v-expansion-panels> -->
          <!-- </div> -->

          <v-card-text>
            <h3 class="tw-text-2xl tw-font-bold">Description</h3>
            {{ eventData.shop.description }}
          </v-card-text>

          <v-card-actions class="justify-center d-block">
            <button
              @click="accessPages('/events/' + eventData.shop.id + '/menu')"
              class="my-2 mx-auto tw-text-lg pa-2 tw-w-3/4 d-block tw-rounded-lg menu-btn"
            >
              menu
            </button>

            <button
              @click="accessPages('/events/' + eventData.shop.id + '/tickets')"
              class="ma-0 my-2 pa-2 tw-text-lg mx-auto tw-w-3/4 d-block tw-rounded-lg reserve-btn"
            >
              reserver
            </button>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { defineComponent } from "vue";

export default {
  data: () => ({
    Icon: "mdi-plus-circle-outline",
    IconColor: "blue",
    loading: false,
    isLoaded: false,
    eventData: {
      shop: {
        name: "",
        address: "",
      },
    },
    picture: null,
  }),
  mounted() {
    this.$axios
      .get("event/" + this.$route.params.id)
      .then((data) => {
        console.log(data.data);
        this.eventData = data.data;
        this.picture = this.eventData.shop.picture;
        this.isLoaded = true;
      })
      .catch((error) => {
        this.isLoaded = true;
      });
  },
  methods: {
    changeIcon() {
      if (this.Icon != "mdi-check-circle-outline") {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.Icon = "mdi-check-circle-outline";
          this.IconColor = "green";
        }, 5000);
      }
    },
    getTime(dateEvent) {
      const pad = (num) => ("0" + num).slice(-2);
      const date = new Date(dateEvent * 1000);
      let hours = date.getHours();
      let minutes = date.getMinutes();
      return pad(hours) + ":" + pad(minutes);
    },
    accessPages(link) {
      this.$router.push(link);
    },
  },
};
</script>

<style scoped>
.bg-color {
  background-color: #0d131c;
}
.menu-btn {
  background-color: #0063ff !important;
}

.reserve-btn {
  background-color: #9e00ff !important;
}
</style>
