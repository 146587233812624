<template>
  <v-container>
    <v-row>
      <v-col
        v-for="ticket in tickets"
        :key="ticket.Ticket_id"
        cols="12"
        lg="4"
        md="4"
        class="tw-inline"
      >
        <event-ticket
          :event_name="ticket.Event.name"
          :shop_name="ticket.shop.name"
          :event_image="ticket.shop.picture"
          :order_ref="ticket.Ticket_id"
          :date="ticket.Event.date"
          :desc="ticket.Event.description"
          :used="ticket.ticket_status ? true : false"
        >
        </event-ticket>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import EventTicket from "@/components/EventTicket.vue";

export default {
  components: {
    EventTicket,
  },
  data: () => ({
    src2: require("@/assets/LogoTakin.png"),
    src: require("@/assets/LogoTakin.png"),
    tickets: [],
  }),
  created() {
    this.$axios
      .get("/usertickets")
      .then((response) => {
        console.log(response);
        this.tickets = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {},
};
</script>
