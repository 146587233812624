<template>
  <v-container fluid class="pa-0">
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="text-h5 lighten-2">
          Confirm Paid Order
        </v-card-title>

        <v-card-text> Are you sure you want to pay this order ? </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green" @click="ConfirmPayOrder()"> Yes </v-btn>

          <v-btn color="red" @click="dialog = false"> No </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-expansion-panels>
      <v-expansion-panel v-for="(item, i) in pageOfItems" :key="i">
        <v-expansion-panel-header>
          <span>{{ dateConv(item.orderInfo.created_at) }}</span>
          <v-spacer></v-spacer>

          <span> Table n'{{ item.table }}</span>
          <v-spacer></v-spacer> <span>{{ item.orderInfo.order_ref }}</span>
          <v-spacer></v-spacer>
          <span>{{ item.orderInfo.amount / 100 }} DH</span>
          <v-spacer></v-spacer>
          <v-icon
            :color="
              item.orderInfo.payment_status == 'accepted' ? 'green' : 'red'
            "
            >mdi-circle</v-icon
          >
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div
            v-for="(itemDetails, index) in item.OrderDetails[
              Object.keys(item.OrderDetails)
            ]"
            :key="index"
            class="tw-block"
          >
            <v-icon color="red">mdi-circle</v-icon>
            <span class="text-h6" style="color: #79b7ff">
              {{ itemDetails.product_name }} X {{ itemDetails.quantity }}
            </span>
          </div>
          <v-btn
            class="mx-auto my-2"
            color="blue"
            @click="payOrder(item)"
            :disabled="
              item.orderInfo.payment_status == 'accepted' ? true : false
            "
          >
            {{ item.orderInfo.payment_status == "accepted" ? "PAID" : "PAY" }}
          </v-btn>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <div class="tw-w-full red">
      <jw-pagination
        class="tw-mx-auto"
        :items="liveOrders"
        @changePage="onChangePage"
      ></jw-pagination>
    </div>
  </v-container>
</template>

<script>
import { SwipeList, SwipeOut } from "vue-swipe-actions";
// eslint-disable-next-line no-unused-vars
import css from "vue-swipe-actions/dist/vue-swipe-actions.css";
import moment from "moment";

export default {
  props: ["change"],
  components: {
    SwipeOut,
    SwipeList,
  },
  data() {
    return {
      enabled: true,
      page: 0,
      revealed: {},
      lastEventDescription: "",
      liveOrders: [],
      pageOfItems: [],
      itemToPaid: null,
      dialog: false,
    };
  },
  watch: {
    change: function (newVal, oldVal) {
      if (newVal.title == "TERMINÉES") {
        // watch it
        this.$axios
          .get(`/shoporders/complete/${this.$store.state.userId}`)
          .then((response) => {
            this.liveOrders = response.data;
            console.log("TERMINÉES");
          });
      }
    },
  },
  mounted() {
    this.$axios
      .get(`/shoporders/complete/${this.$store.state.userId}`)
      .then((response) => {
        this.liveOrders = response.data;
      });
  },
  methods: {
    dateConv(date) {
      return moment(date).format("MM/D");
    },
    onChangePage(pageOfItems) {
      // update page of items
      this.pageOfItems = pageOfItems;
    },
    payOrder(item) {
      this.itemToPaid = item;
      this.dialog = true;
      console.log(this.itemToPaid.orderInfo.id);
    },
    ConfirmPayOrder() {
      this.dialog = false;
      this.$axios
        .post(`/payorder/${this.itemToPaid.orderInfo.id}`)
        .then((res) => {
          if (res.data.status) {
            this.$axios
              .get(`/shoporders/complete/${this.$store.state.userId}`)
              .then((response) => {
                this.liveOrders = response.data;
                this.$message({
                  message: "Order paid",
                  type: "success",
                });
              });
          }
        });
    },
  },
};
</script>
<style>
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");

/* app specific styles */

.swipeout-action {
  display: flex;
  align-items: center;
  padding: 0 3rem;
  cursor: pointer;
  left: 0;
}
/* https://developer.apple.com/design/human-interface-guidelines/ios/visual-design/color/ */
.swipeout-action.blue {
  color: white;
  background-color: rgb(0, 122, 255);
}
.swipeout-action.blue:hover {
  background-color: darken(rgb(0, 122, 255), 5%);
}
.swipeout-action.purple {
  color: white;
  background-color: rgb(88, 86, 214);
}
.swipeout-action.purple:hover {
  background-color: darken(rgb(88, 86, 214), 5%);
}

.swipeout-action.red {
  color: white;
  background-color: rgb(255, 59, 48);
}
.swipeout-action.red:hover {
  background-color: darken(rgb(255, 59, 48), 5%);
}
.swipeout-action.green {
  color: white;
  background-color: rgb(76, 217, 100);
}
.swipeout-action.green:hover {
  background-color: darken(rgb(76, 217, 100), 5%);
}

.swipeout-list-item {
  flex: 1;
  border-bottom: 1px solid lightgray;
}

.swipeout-list-item:last-of-type {
  border-bottom: none;
}

.card {
  width: 100%;
  background-color: #0d131c;
  border-radius: 3px;
  box-shadow: none;
}
.card-content {
  /* padding: 1rem; */
}
.transition-right {
  transform: translate3d(100%, 0, 0) !important;
}
.transition-left {
  transform: translate3d(-100%, 0, 0) !important;
}

.toolbar {
  display: flex;
  align-items: center;
}

.toolbar .toolbar-section {
  flex: 0 0 auto;
}

.toolbar .toolbar-section--center {
  flex: 1000 1 0%;
}

.bg-darke {
  background-color: #0d131c;
}

.bg-lighten {
  background-color: #1d2634;
}

.center-text-orders {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
</style>
