<template>
  <v-container fluid>
    <!-- <div style="" class="filter"> -->
    <!-- dialog for non authentificated users -->
    <v-dialog v-model="authDialog" width="500">
      <v-card color="#131C28">
        <v-card-title class="text-h5 blue-grey lighten-4 lighten-2">
          You can't do this action
        </v-card-title>

        <v-card-text class="my-2">
          Please login or create an account to benefit from all our features.
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="authDialog = false"> Close </v-btn>
          <v-btn color="primary" text to="'/login'"> Login </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end  v-dialog -->

    <v-bottom-sheet
      color="#131C28"
      v-model="cart"
      scrollable
      transition="bottom-sheet-transition"
    >
      <v-card max-height="400px" class="my-1" color="#131C28">
        <v-divider></v-divider>
        <v-card-text>
          <v-list color="#131C28">
            <v-list-item v-for="item in cartShop" :key="item.id" class="my-2">
              <v-list-item-content>
                <v-list-item-title v-text="item.name"></v-list-item-title>
              </v-list-item-content>

              <v-btn icon @click="minusQuantity(item)"
                ><v-icon>mdi-minus</v-icon></v-btn
              >
              <v-list-item-avatar class="ma-0">
                {{ item.quantity }}
              </v-list-item-avatar>
              <v-btn icon @click="plusQuantity(item)"
                ><v-icon>mdi-plus</v-icon></v-btn
              >
            </v-list-item>
            <v-divider></v-divider>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-btn
            :loading="loading"
            @click="sendOrder()"
            class="tw-w-full"
            color="#67C9FF"
          >
            Confirm Order
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-bottom-sheet>
    <!-- </v-navigation-drawer> -->
    <v-speed-dial
      v-if="!disabled"
      v-model="fab"
      bottom
      fixed
      right
      direction="top"
      transition="slide-y-reverse-transition"
    >
      <template v-slot:activator>
        <v-badge
          bordered
          :content="quantity"
          :value="quantity"
          overlap
          color="green"
        >
          <v-btn
            v-model="fab"
            color="blue darken-2"
            dark
            @click="cart = true"
            block
          >
            Commander
          </v-btn>
        </v-badge>
      </template>
    </v-speed-dial>
    <v-chip-group
      mandatory
      active-class="white--text text--white"
      class="my-2"
      v-model="showbycategorie"
    >
      <v-chip
        class="mx-2"
        style="touch-action: pan-y"
        color="#4E5C72"
        :value="item"
        v-for="item in categories"
        :key="item.name"
        >{{ item.name }}</v-chip
      >
    </v-chip-group>

    <v-row>
      <slot v-for="catgories in itemsbycategorie">
        <v-col
          v-for="item in catgories.products"
          :key="item.id"
          cols="6"
          lg="4"
          md="6"
          sm="6"
          xs="12"
          class="pa-0"
        >
          <v-card
            color="#0D131C"
            @click="plusquantity(item)"
            class="my-1 ma-1 rounded-lg"
            style="
              background: linear-gradient(
                rgba(0, 0, 0, 0.1),
                rgba(10, 137, 255, 0.2)
              );
            "
          >
            <v-img
              class="mx-auto mt-3 tw-rounded-lg img-size"
              contain
              :src="item.picture"
            >
            </v-img>
            <span class="text-h4 mx-3 tw-block">{{ item.name }} </span>
            <span
              class="tw-text-3xl text-color-price tw-font-bold mx-2 mb-2 tw-block"
              >{{ item.price }}$</span
            >
          </v-card>
        </v-col>
      </slot>
    </v-row>
    <!-- </div> -->

    <v-row> </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    shop: null,
    categories: [],
    showbycategorie: 0,
    itemsbycategorie: [],
    products: [],
    cartShop: [],
    authDialog: false,
    quantity: 0,
    fab: false,
    cart: false,
    total: 0,
    cartItems: null,
    loading: false,
  }),
  computed: {
    disabled: function () {
      if (this.quantity > 0) return false;
      return true;
    },
  },
  watch: {
    showbycategorie: function () {
      this.itemsbycategorie = [];
      let length = this.products.length;
      console.log(this.products);
      for (let i = 0; i < length; i++) {
        if (this.showbycategorie.id === this.products[i].CategorieID)
          this.itemsbycategorie.push(this.products[i]);
        else if (this.showbycategorie.id === 0)
          this.itemsbycategorie = this.products;
      }
      console.log(this.itemsbycategorie);
    },
    cartShop: function () {
      if (!this.cartShop.length) this.cart = false;
    },
  },
  mounted() {
    if (!this.$store.state.table) {
      let path = this.$router.history.current.path.replace("menu", "table");
      this.$router.push(path);
    }

    this.$axios.get(`/categories/${this.$route.params.id}`).then((res) => {
      this.categories = res.data;
      this.categories.unshift({ name: "All", id: 0 });
    });

    this.$axios.get(`/products/${this.$route.params.id}`).then((res) => {
      this.products = res.data.products;
      this.itemsbycategorie = this.products;
      // console.log(this.products)
    });
    this.cartShop = JSON.parse(localStorage.getItem("cart"));
    // this.itemsbycategorie = this.products
    if (!this.cartShop) this.cartShop = [];
    for (let i = 0; i < this.cartShop.length; i++) {
      this.quantity += this.cartShop[i].quantity;
    }
  },
  methods: {
    minusQuantity(item) {
      item.quantity -= 1;
      this.quantity -= 1;
      if (item.quantity <= 0) {
        let index = this.cartShop
          .map(function (e) {
            return e.id;
          })
          .indexOf(item.id);
        this.cartShop.splice(index, 1);
      }
      if (localStorage.getItem("cart") === null)
        localStorage.setItem("cart", JSON.stringify(this.cartShop));
      else {
        localStorage.removeItem("cart");
        localStorage.setItem("cart", JSON.stringify(this.cartShop));
      }
      console.log(this.cartShop.length);
    },
    plusQuantity(item) {
      console.log(this.$store.state.isAuthenticated);
      if (!this.$store.state.isAuthenticated) console.log("you cant");
      else {
        item.quantity += 1;
        this.quantity += 1;
        if (localStorage.getItem("cart") === null)
          localStorage.setItem("cart", JSON.stringify(this.cartShop));
        else {
          localStorage.removeItem("cart");
          localStorage.setItem("cart", JSON.stringify(this.cartShop));
        }
      }
    },
    filterbycategory(item) {
      this.itemsbycategorie = [];
      if (
        this.categories[this.showbycategorie] === item.category ||
        this.categories[this.showbycategorie] === "all"
      )
        this.itemsbycategorie.push(item);
    },
    plusquantity(item) {
      if (!this.$store.state.isAuthenticated) this.authDialog = true;
      else {
        let itemDetails = {
          id: item.id,
          name: item.name,
          price: item.price,
          quantity: 1,
        };
        if (
          this.cartShop.filter(function (e) {
            return e.id === item.id;
          }).length > 0
        ) {
          // console.log(this.cartShop.map(function(e) { return e.id; }).indexOf(item.id))
          let index = this.cartShop
            .map(function (e) {
              return e.id;
            })
            .indexOf(item.id);
          this.cartShop[index].quantity += 1;
        } else this.cartShop.push(itemDetails);
        this.quantity += 1;
        if (localStorage.getItem("cart") === null)
          localStorage.setItem("cart", JSON.stringify(this.cartShop));
        else {
          localStorage.removeItem("cart");
          localStorage.setItem("cart", JSON.stringify(this.cartShop));
        }
      }
    },
    sendOrder() {
      this.cartItems = JSON.parse(localStorage.getItem("cart"));
      if (!this.cartItems) {
        this.$router.push(`${this.$route.path.replace("cart", "menu")}`);
      }
      if (!this.cartItems) this.cartItems = [];
      for (let i = 0; i < this.cartItems.length; i++) {
        this.quantity += this.cartItems[i].quantity;
        this.total +=
          parseFloat(this.cartItems[i].quantity) *
          parseFloat(this.cartItems[i].price);
      }
      let orderData = {
        amount: (Math.round(this.total * 100) / 100).toFixed(2) * 100,
        items: JSON.parse(localStorage.getItem("cart")),
        payment_type: "Init",
        table: !this.$store.state.table ? 0 : parseInt(this.$store.state.table),
        shop_id: this.$route.params.id,
      };
      this.loading = true;
      this.$axios.post("/placeorder", orderData).then((res) => {
        if (res.data.status) {
          localStorage.setItem("userOrder", 1);
          localStorage.removeItem("cart");
          localStorage.setItem("cart", JSON.stringify([]));
          this.cartShop = [];
          this.cartItems = [];
          this.quantity = 0;
          this.fab = false;
          this.cart = false;
        }
        this.loading = false;
      });
    },
  },
};
</script>

<style scoped>
::-webkit-scrollbar {
  width: 0px;
  display: none;
}

.filter {
  scrollbar-width: none;
  overflow-x: auto;
  white-space: nowrap;
}

.text-color-price {
  color: #68c9ff;
}

.img-size {
  width: 250px;
  height: 250px;
}
</style>
