<template>
  <v-container>
    <v-card>
      <v-tabs
        v-model="tab"
        background-color=""
        active-class="tw-bg-pink-400 white--text"
        centered
        dark
        icons-and-text
      >
        <v-tabs-slider></v-tabs-slider>

        <v-tab href="#tab-1" class="white--text" active-class="tw-text-white">
          À PRÉPARER
        </v-tab>

        <v-tab href="#tab-2"> EN ATTENTE </v-tab>

        <v-tab href="#tab-3"> TERMINÉES </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item v-for="i in 3" :key="i" :value="'tab-' + i">
          <v-card flat>
            <v-card-text>{{ text }}</v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    tab: null,
    text: "Lorem ipsum",
  }),
};
</script>
