<template>
  <v-container class="pa-0 py-2">
    <v-bottom-sheet
      color="#131C28"
      v-model="payments"
      scrollable
      transition="bottom-sheet-transition"
    >
      <v-card max-height="80%" color="#131C28">
        <v-divider class="mx-auto" @click="payments = !payments"></v-divider>
        <v-card-title class="text-center mx-auto">
          Moyen de paiments
        </v-card-title>

        <v-card-text class="ma-0">
          <v-expansion-panels elevation="0" color="#131C28" class="ma-2" popout>
            <v-expansion-panel elevation="0" class="expansion-panel-color">
              <v-expansion-panel-header
                elevation="0"
                color="#131C28"
                expand-icon="mdi-credit-card-outline"
                disable-icon-rotate
              >
                Credit Card TPE
              </v-expansion-panel-header>

              <v-expansion-panel-content color="#131C28" class="pa-0 ma-0">
                <!--  <div class="card-form">
    <div class="card-list">
      <vue-paycard 
        :inputFields="inputFields"
        :valueFields="valueFields"
        :labels="labels"
        :isCardNumberMasked="isCardNumberMasked"
        :hasRandomBackgrounds="hasRandomBackgrounds"
        :backgroundImage="backgroundImage"
        :setType="setType"
        @get-type="changeType">
      </vue-paycard>
      <div class="card-form__inner">
        <v-col cols="12" lg="8" md="8" sm="12" class="card-input">
          <v-text-field
          outlined
            type="tel"
            label="Numéro de carte"
            :id="inputFields.cardNumber"
            title="Number"
            class="card-input__input"
            :value="valueFields.cardNumber"
            @input="changeNumber"
            data-card-field
            autocomplete="off"
            :maxlength="cardNumberMaxLength"
          />
        </v-col>
        <v-col cols="12" lg="8" md="8" sm="12" class="card-input">
          <v-text-field
          outlined
            type="text"
            label="Titulaire"
            :id="inputFields.cardName"
            title="Name"
            v-letter-only
            class="card-input__input"
            :value="valueFields.cardName"
            @input="changeName"
            data-card-field
            autocomplete="off"
          />
        </v-col>
        <v-row class="d-flex">
        <v-col cols="12" lg="3" md="3" sm="4" class="card-form__col -cvv">
              <v-text-field
              label="Mois"
              outlined
                class="card-input__input -select"
                :id="inputFields.cardMonth"
                aria-label="Card Month"
                maxlength="2"
                title="Month"
                v-model="valueFields.cardMonth"
                data-card-field
              >
              </v-text-field>
        </v-col>
        <v-col cols="12" lg="3" md="3" sm="4" class="card-form__col -cvv">
              <v-text-field
              outlined
              label="Annee"
                class="card-input__input -select"
                :id="inputFields.cardYear"
                aria-label="Card year"
                maxlength="4"
                title="Year"
                v-model="valueFields.cardYear"
                data-card-field
              >
              </v-text-field>
        </v-col>
        <v-col cols="12" lg="3" md="3" sm="4" class="card-form__col -cvv">

              <v-text-field
              outlined
                type="tel"
                label="CVV"
                title="CVV"
                class="card-input__input"
                v-number-only
                :id="inputFields.cardCvv"
                maxlength="4"
                :value="valueFields.cardCvv"
                @input="changeCvv"
                data-card-field
                autocomplete="off"
              />
          </v-col>
        </v-row>
      </div>
    </div>
  </div>

  <v-btn color="green" to="Waitlist" class="tw-w-full">Pay</v-btn> -->
                <div class="mr-1">
                  <!-- <AdyenDropin
                    @status="manageStatus"
                    type="order"
                    @done="orderDone"
                    :amount="(Math.round(total * 100) / 100).toFixed(2)"
                  /> -->

                  <v-btn
                    color="#0063FF"
                    @click="callWaiterToPay('TPE')"
                    block
                    :loading="loading"
                    class="mx-auto"
                  >
                    Appeler
                  </v-btn>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel class="expansion-panel-color">
              <v-expansion-panel-header
                color="#131C28"
                expand-icon="mdi-cash-multiple"
                disable-icon-rotate
              >
                Espece
              </v-expansion-panel-header>
              <v-expansion-panel-content color="#131C28">
                <v-col>
                  <span class="my-1"> Prix total de la commande : </span>
                  <span class="text-h5 my-1 tw-font-extrabold tw-block">
                    {{ (Math.round(total * 100) / 100).toFixed(2) }} dhs
                  </span>
                  <span class="my-1"> Aurez-vous besoin de monnaie ? </span>
                  <v-radio-group v-model="Espece" @change="showInputSomme()">
                    <v-radio label="J’ai la somme exacte" :value="1"></v-radio>

                    <v-radio label="Je vais payer (montant)" :value="2">
                    </v-radio>

                    <v-text-field
                      type="number"
                      hide-spin-buttons
                      transition="slide-y-transition"
                      v-if="showInput"
                      outlined
                      v-model="cash"
                      label="Somme"
                    >
                    </v-text-field>

                    <v-radio label="Je ne sais pas " :value="3"></v-radio>
                  </v-radio-group>
                </v-col>

                <v-btn
                  color="green"
                  class="tw-w-full"
                  @click="callWaiterToPay('cash')"
                  :loading="loading"
                  :disabled="!Espece"
                >
                  Valider
                </v-btn>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
      </v-card>
    </v-bottom-sheet>
    <v-row class="">
      <v-col cols="12">
        <v-card color="#141B26" elevation="0" class="align-center">
          <v-img
            class="mx-auto my-4"
            contain
            max-height="150"
            max-width="250"
            :src="shopPicture"
          >
          </v-img>

          <div class="w-full mb-3">
            <span class="tw-text-sm ml-8"> Votre addition : Table 12 </span>
          </div>
          <v-divider class="mx-auto mb-2"></v-divider>

          <slot v-for="items in orders" class="">
            <div v-if="items.orderInfo" :key="items.orderInfo.id">
              <slot v-if="items.OrderDetails">
                <v-list-item v-for="item in items.OrderDetails" :key="item.id">
                  <span
                    class="tw-border-solid tw-border-2 tw-border-sky-500 tw-rounded-lg span-border mx-4 px-2 tw-bg-kanoa"
                  >
                    {{ item.quantity }}
                  </span>

                  <v-list-item-content>
                    <v-list-item-title
                      class=""
                      v-text="item.product_name"
                    ></v-list-item-title>
                  </v-list-item-content>

                  <span class="">
                    {{
                      (
                        parseFloat(item.quantity) *
                        parseFloat(item.product_price)
                      ).toString() + " DH"
                    }}
                  </span>
                </v-list-item>
              </slot>
            </div>
          </slot>

          <v-divider class="mx-auto my-2"></v-divider>

          <div class="d-flex px-4 pl-4 mt-6 align-center">
            <span class="text-color tw-text-xl"> Total </span>
            <v-spacer></v-spacer>
            <span class="text-h4 tw-font-bold">
              {{ (Math.round(total * 100) / 100).toFixed(2) }} dhs
            </span>
          </div>
          <v-card-actions>
            <v-btn
              color="#0063FF"
              class="tw-w-full btn-cart"
              @click="openPayments()"
            >
              <span class="my-4"> Régler l’addition </span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AdyenDropin from "../../components/AdyenPayment";

export default {
  components: {
    AdyenDropin,
  },
  directives: {
    "number-only": {
      bind(el) {
        function checkValue(event) {
          event.target.value = event.target.value.replace(/[^0-9]/g, "");
          if (event.charCode >= 48 && event.charCode <= 57) {
            return true;
          }
          event.preventDefault();
        }
        el.addEventListener("keypress", checkValue);
      },
    },
    "letter-only": {
      bind(el) {
        function checkValue(event) {
          if (event.charCode >= 48 && event.charCode <= 57) {
            event.preventDefault();
          }
          return true;
        }
        el.addEventListener("keypress", checkValue);
      },
    },
  },
  data: () => ({
    cash: null,
    showInput: false,
    Espece: null,
    cart: {},
    orders: null,
    shopPicture: "",
    quantity: null,
    minCardYear: new Date().getFullYear(),
    mainCardNumber: "",
    cardNumberMaxLength: 19,
    generatedType: "",
    activity: false,
    restauName: null,
    payments: false,
    selectedMethod: null,
    methods: ["card", "cash"],
    total: null,
    loading: false,
  }),
  computed: {
    minCardMonth() {
      if (this.valueFields.cardYear === this.minCardYear) {
        return new Date().getMonth() + 1;
      }
      return 1;
    },
  },
  watch: {
    cardYear() {
      if (this.valueFields.cardMonth < this.minCardMonth) {
        this.valueFields.cardMonth = "";
      }
    },
  },
  mounted() {
    this.$axios.get("/shop/" + this.$route.params.id).then((res) => {
      this.restauName = res.data[0].name;
      this.shopPicture = res.data[0].picture;
    });
    this.$axios.get(`/userordershop/${this.$route.params.id}`).then((res) => {
      this.orders = res.data;
      this.total = res.data.total;
    });

    this.$axios.get("/shop/" + this.$route.params.id).then((res) => {
      this.restauName = res.data[0].name;
    });

    this.cart = JSON.parse(localStorage.getItem("cart"));
    if (!this.cart) {
      this.$router.push(`${this.$route.path.replace("cart", "menu")}`);
    }
    if (!this.cart) this.cart = [];
    for (let i = 0; i < this.cart.length; i++) {
      this.quantity += this.cart[i].quantity;
      this.total +=
        parseFloat(this.cart[i].quantity) * parseFloat(this.cart[i].price);
    }
  },
  methods: {
    back() {
      this.$router.go(-1);
    },

    callWaiterToPay(method) {
      this.loading = true;
      this.$axios
        .post(`/callwaitertopay/${this.$route.params.id}`, {
          table_num: this.$store.state.table,
          method: method,
          cash: this.cash,
        })
        .then(() => {
          this.cash = null;
          this.loading = false;
        });
    },
    showInputSomme() {
      if (this.Espece == 2) this.showInput = true;
      else this.showInput = false;
    },
    minusQuantity(item) {
      item.quantity -= 1;
      this.quantity -= 1;
      this.total -= parseFloat(item.price);
      if (item.quantity <= 0) {
        let index = this.cart
          .map(function (e) {
            return e.id;
          })
          .indexOf(item.id);
        this.cart.splice(index, 1);
      }
      if (localStorage.getItem("cart") === null)
        localStorage.setItem("cart", JSON.stringify(this.cart));
      else {
        localStorage.removeItem("cart");
        localStorage.setItem("cart", JSON.stringify(this.cart));
      }
    },
    plusQuantity(item) {
      item.quantity += 1;
      this.quantity += 1;
      this.total += parseFloat(item.price);
      if (localStorage.getItem("cart") === null)
        localStorage.setItem("cart", JSON.stringify(this.cart));
      else {
        localStorage.removeItem("cart");
        localStorage.setItem("cart", JSON.stringify(this.cart));
      }
    },
    openPayments() {
      this.payments = true;
    },
    manageStatus(event) {
      // console.log('this is event' , event)
    },

    visitMenu() {
      this.$router.push(`${this.$route.path.replace("cart", "menu")}`);
    },
    orderDone() {
      localStorage.removeItem("cart");
      console.log(this.$route.path.replace("cart", "waitlist"));
      this.$router.push(`${this.$route.path.replace("cart", "waitlist")}`);
    },

    placeOrder() {
      let orderData = {
        amount: (Math.round(this.total * 100) / 100).toFixed(2) * 100,
        items: JSON.parse(localStorage.getItem("cart")),
        payment_type: "cash",
        table: !this.$store.state.table ? 0 : parseInt(this.$store.state.table),
        shop_id: this.$route.params.id,
      };
      this.loading = true;
      this.$axios.post("/placeorder", orderData).then((res) => {
        // let waitlist =
        // if (res.data.status) {
        //   this.orderDone();
        // }
        this.loading = false;
      });
      console.log(orderData);
    },
    changeName(e) {
      this.valueFields.cardName = e;
      this.$emit("input-card-name", this.valueFields.cardName);
    },
    changeType(val) {
      this.generatedType = val;
    },
    changeNumber(e) {
      this.valueFields.cardNumber = e;
      const value = this.valueFields.cardNumber.replace(/\D/g, "");
      // american express, 15 digits
      if (/^3[47]\d{0,13}$/.test(value)) {
        this.valueFields.cardNumber = value
          .replace(/(\d{4})/, "$1 ")
          .replace(/(\d{4}) (\d{6})/, "$1 $2 ");
        this.cardNumberMaxLength = 17;
      } else if (/^3(?:0[0-5]|[68]\d)\d{0,11}$/.test(value)) {
        // diner's club, 14 digits
        this.valueFields.cardNumber = value
          .replace(/(\d{4})/, "$1 ")
          .replace(/(\d{4}) (\d{6})/, "$1 $2 ");
        this.cardNumberMaxLength = 16;
      } else if (/^62[0-9]\d*/.test(value)) {
        this.valueFields.cardNumber = value
          .replace(/(\d{6})/, "$1 ")
          .replace(/(\d{6}) (\d{7})/, "$1 $2 ")
          .replace(/(\d{6}) (\d{7}) (\d{6})/, "$1 $2 $3 ")
          .replace(/(\d{5}) (\d{5}) (\d{5}) (\d{4})/, "$1 $2 $3 $4");
        this.cardNumberMaxLength = 21;
      } else if (/^\d{0,16}$/.test(value)) {
        // regular cc number, 16 digits
        this.valueFields.cardNumber = value
          .replace(/(\d{4})/, "$1 ")
          .replace(/(\d{4}) (\d{4})/, "$1 $2 ")
          .replace(/(\d{4}) (\d{4}) (\d{4})/, "$1 $2 $3 ");
        this.cardNumberMaxLength = 19;
      }

      if (e.inputType == "deleteContentBackward") {
        const lastChar = this.valueFields.cardNumber.substring(
          this.valueFields.cardNumber.length,
          this.valueFields.cardNumber.length - 1
        );

        if (lastChar == " ") {
          this.valueFields.cardNumber = this.valueFields.cardNumber.substring(
            0,
            this.valueFields.cardNumber.length - 1
          );
        }
      }
      this.$emit("input-card-number", this.valueFields.cardNumber);
    },
    changeMonth() {
      this.$emit("input-card-month", this.valueFields.cardMonth);
    },
    changeYear() {
      this.$emit("input-card-year", this.valueFields.cardYear);
    },
    changeCvv(e) {
      this.valueFields.cardCvv = e;
      this.$emit("input-card-cvv", this.valueFields.cardCvv);
    },
    generateMonthValue(n) {
      return n < 10 ? `0${n}` : n;
    },
  },
  props: {
    valueFields: {
      type: Object,
      default: () => ({
        cardName: "",
        cardNumber: "",
        cardMonth: null,
        cardYear: null,
        cardCvv: null,
      }),
    },
    inputFields: {
      type: Object,
      default: () => ({
        cardNumber: "v-card-number",
        cardName: "v-card-name",
        cardMonth: "v-card-month",
        cardYear: "v-card-year",
        cardCvv: "v-card-cvv",
      }),
    },
    labels: {
      type: Object,
      default: () => ({
        cardName: "Full Name",
        cardHolder: "Card Holder",
        cardMonth: "MM",
        cardYear: "YY",
        cardExpires: "Expires",
        cardCvv: "CVV",
      }),
    },
    isCardNumberMasked: {
      type: Boolean,
      default: true,
    },
    hasRandomBackgrounds: {
      type: Boolean,
      default: true,
    },
    backgroundImage: {
      type: [String, Number],
      default: "",
    },
    setType: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped>
.span-border {
  border-color: #32435c;
}

.text-color {
  color: #a8bbda;
}

.header-color {
  color: #79b8ff;
  font-weight: 700;
}

.expansion-panel-color {
  background-color: #131c28;
}

.v-divider {
  border-width: thin 11px 0.7px 0 !important;
  max-width: 80%;
}

.btn-cart {
  padding: 23px 8px !important;
  border-color: #8193ae;
}

.divider-color-white {
  border-color: rgb(245, 245, 245) !important;
}
</style>
