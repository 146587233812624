<template>
  <div class="auth-wrapper auth-v1" v-on:keyup.enter="login()">
    <div class="auth-inner">
      <v-card elevation="0" class="auth-card" color="#0D131C">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-0">
          <router-link to="/" class="d-flex align-center">
            <v-img
              :src="require('@/assets/LogoTakin.png')"
              max-height="130px"
              max-width="130px"
              height="130px"
              width="130px"
              alt="logo"
              contain
              class="me-3"
            ></v-img>
          </router-link>
        </v-card-title>
        <v-expand-transition>
          <v-alert v-show="expand" dense :type="typeAlert" style="text-center">
            {{ alerttext }}
          </v-alert>
        </v-expand-transition>
        <v-card-title class="d-flex mb-2 align-center justify-center">
          <h2 class="text-h3 font-weight-bold">Kanoa</h2>
        </v-card-title>

        <!-- title -->
        <!-- <v-card-text>
          <p class="text-2xl font-weight-semibold text--primary mb-2">
            Welcome to Materio! 👋🏻
          </p>
          <p class="mb-2">
            Please sign-in to your account and start the adventure
          </p>
        </v-card-text> -->

        <!-- login form -->
        <v-card-text>
          <v-form>
            <v-text-field
              v-model="email"
              outlined
              label="Email"
              placeholder="john@example.com"
              hide-details
              class="mb-3"
            ></v-text-field>

            <v-text-field
              v-model="password"
              outlined
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Password"
              placeholder="············"
              :append-icon="
                isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline
              "
              hide-details
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>

            <div class="d-flex align-center justify-space-between flex-wrap">
              <v-checkbox label="Remember Me" hide-details class="me-3 mt-1">
              </v-checkbox>

              <!-- forgot link -->
              <a href="javascript:void(0)" class="mt-1"> Forgot Password? </a>
            </div>

            <v-btn
              block
              color="primary"
              @click="login"
              class="mt-6"
              :loading="loading"
            >
              Login
            </v-btn>
          </v-form>
        </v-card-text>

        <!-- create new account  -->
        <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
          <span class="me-2"> New on our platform? </span>
          <router-link :to="'/register'"> Create an account </router-link>
        </v-card-text>

        <!-- divider -->
        <!-- <v-card-text class="d-flex align-center mt-2">
          <v-divider></v-divider>
          <span class="mx-5">or</span>
          <v-divider></v-divider>
        </v-card-text> -->

        <!-- social links -->
        <!-- <v-card-actions class="d-flex justify-center">
          <v-btn
            v-for="link in socialLink"
            :key="link.icon"
            icon
            class="ms-1"
          >
            <v-icon :color="$vuetify.theme.dark ? link.colorInDark : link.color">
              {{ link.icon }}
            </v-icon>
          </v-btn>
        </v-card-actions> -->
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <!-- <img
      class="auth-mask-bg"
      height="173"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
    > -->

    <!-- tree -->
    <!-- <v-img
      class="auth-tree"
      width="247"
      height="185"
      src="@/assets/images/misc/tree.png"
    ></v-img> -->

    <!-- tree  -->
    <!-- <v-img
      class="auth-tree-3"
      width="377"
      height="289"
      src="@/assets/images/misc/tree-3.png"
    ></v-img> -->
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiFacebook,
  mdiTwitter,
  mdiGithub,
  mdiGoogle,
  mdiEyeOutline,
  mdiEyeOffOutline,
} from "@mdi/js";
import { ref } from "@vue/composition-api";

export default {
  data: () => ({
    alerttext: "",
    loading: false,
    expand: false,
    typeAlert: "info",
    socialLink: [
      {
        icon: mdiFacebook,
        color: "#4267b2",
        colorInDark: "#4267b2",
      },
      {
        icon: mdiTwitter,
        color: "#1da1f2",
        colorInDark: "#1da1f2",
      },
      {
        icon: mdiGithub,
        color: "#272727",
        colorInDark: "#fff",
      },
      {
        icon: mdiGoogle,
        color: "#db4437",
        colorInDark: "#db4437",
      },
    ],

    isPasswordVisible: false,
    username: "",
    email: "",
    password: "",

    icons: {
      mdiEyeOutline,
      mdiEyeOffOutline,
    },
  }),

  methods: {
    login() {
      this.loading = true;

      this.$store
        .dispatch("login", {
          email: this.email,
          password: this.password,
        })
        .then((data) => {
          console.log(data);
          if (data && data.status == "error") {
            this.typeAlert = "error";
            this.expand = true;
            this.alerttext = "les informations d'identification invalides";
            setTimeout(() => (this.expand = false), 2000);
          } else {
            this.typeAlert = "success";
            this.expand = true;
            this.alerttext = "connecté avec succès";
            setTimeout(() => (this.expand = false), 2000);
            setTimeout(
              () => (this.loading = false),
              this.$router.push("/"),
              window.location.reload(),
              5000
            );
          }
        });
    },
  },
};
</script>

<style lang="scss">
@import "~@/plugins/vuetify/default-preset/preset/pages/auth.scss";
</style>
