<template>
  <v-container class="tw-h-full">
    <v-img
      class="mx-auto mt-4 mb-14"
      contain
      max-height="150"
      max-width="250"
      :src="shop.picture"
    >
    </v-img>

    <span class="mt-12 block"> Table {{ $store.state.table }} </span>
    <v-btn
      class="mt-2"
      :to="`/${$route.params.id}/menu`"
      block
      color="#9E00FF"
      large
    >
      <span class=""> Voir le menu </span>
    </v-btn>
    <v-row class="my-1 justify-center">
      <v-col class="pa-0 mx-2">
        <v-btn
          block
          small
          class="my-2 btn-cart py-6 text-subtitle-2"
          color="#0063FF"
          :disabled="!!!$store.state.isAuthenticated"
          :loading="loading"
          @click="callWaiterToTable()"
        >
          Appeler serveur
        </v-btn>
      </v-col>

      <v-col class="pa-0 mx-2">
        <v-btn
          :disabled="this.cart > 0 ? false : true"
          color="#0063FF"
          block
          small
          :to="`/${$route.params.id}/cart`"
          class="my-2 btn-cart py-6 text-subtitle-2"
        >
          <span class="py-4"> Régler l’addition </span>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    cart: 0,
    loading: false,
    shop: {
      picture: null,
      name: null,
    },
  }),
  created() {
    this.cart = JSON.parse(localStorage.getItem("userOrder"));
    this.$store.dispatch("addTableUser", this.$route.params.table);
    this.$axios.get(`/shop/${this.$route.params.id}`).then((res) => {
      this.shop = res.data[0];
      console.log(this.shop);
    });
  },

  methods: {
    callWaiterToTable() {
      this.loading = true;
      this.$axios
        .post(`/callwaiter/${this.$route.params.id}`, {
          table_num: this.$route.params.table,
        })
        .then(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style></style>
