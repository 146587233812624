<template id="con">
  <v-app id="app" class="safe-areas top-safe overflow-hidden">
    <v-navigation-drawer
      v-if="!!$store.state.isAuthenticated"
      color="#131C28"
      v-model="drawer"
      :mini-variant.sync="mini"
      app
    >
      <v-list-item class="px-2">
        <v-list-item-avatar>
          <v-img
            :src="'https://kanoa-api.win' + $store.state.userImage"
          ></v-img>
        </v-list-item-avatar>

        <v-list-item-title>{{ $store.state.userName }}</v-list-item-title>
      </v-list-item>

      <v-divider></v-divider>

      <slot v-for="item in pages">
        <v-list dense v-if="checkRole(item)" :key="item.title">
          <v-list-item :to="item.to" link>
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </slot>
      <v-list dense>
        <v-list-item
          v-if="!!$store.state.isAuthenticated"
          @click="$store.dispatch('logout')"
        >
          <v-list-item-icon>
            <v-icon color="red">mdi-logout</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar id="header" app color="#131C28" v-if="checkRoute()">
      <v-btn
        icon
        v-if="!!$store.state.isAuthenticated && !showBackButton"
        @click="drawer = !drawer"
      >
        <v-icon>mdi-menu</v-icon>
      </v-btn>

      <BackButton v-if="showBackButton" />

      <div justify="center" class="tw-mx-auto tw-w-full">
        <v-img
          max-height="70"
          max-width="70"
          contain
          class="tw-mx-auto"
          :src="require('@/assets/logo_complet.png')"
        >
        </v-img>
      </div>
      <v-spacer></v-spacer>

      <v-btn v-if="!!$store.state.isAuthenticated" icon to="/profile">
        <v-icon>mdi-account</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main class="bg-color">
      <!--  -->

      <router-view></router-view>
    </v-main>

    <v-footer padless>
      <v-col class="text-center d-flex" cols="12"> Powred by Kanoa </v-col>
    </v-footer>
  </v-app>
</template>

<script>
import { StatusBar, Style } from "@capacitor/status-bar";
import { app as firebaseApp } from "./firebase";
import {
  getToken,
  getMessaging,
  onMessage,
  deleteToken,
} from "firebase/messaging";
import { register as registerSW } from "register-service-worker";
import Sound from "./assets/cash.mp3";
import { mapState } from "vuex";
import BackButton from "./components/BackButton.vue";

export default {
  //
  components: {
    BackButton,
  },
  computed: {
    ...mapState({
      myState: (state) => state.myModule.myState,
    }),
  },

  watch: {
    $route: {
      handler: function (val, oldVal) {
        console.log("router changed ");
        // Update the value of currentPath when the route changes
        let show =
          val.path === "/scan" || val.path === "/dashboard/orders/live"
            ? false
            : true;
        console.log("watch show -->", show);

        this.showBackButton = show;
      },
      deep: true,
    },
  },
  async created() {
    let show =
      this.$route.path === "/scan" ||
      this.$route.path === "/dashboard/orders/live"
        ? false
        : true;
    console.log(show);

    this.showBackButton = show;

    if (!!this.$store.state.isAuthenticated) {
      this.callWaiter();
      this.messaging = getMessaging(firebaseApp);
      if (this.isSubscribed())
        this.clientToken = await this.getSubscribedToken();
      await this.registerServiceWorker();
    }

    const hideStatusBar = async () => {
      await StatusBar.hide();
    };
    this.btnBack = !(this.$route.name == "Home");

    if (!!this.$store.state.isAuthenticated)
      if (this.$store.state.Role == "shop") this.shopOrders();
      else this.userOrders();

    if (!!this.$store.state.isAuthenticated)
      if (this.$store.state.Role == "shop")
        this.$router.push({ name: "liveorders" });
      else this.$router.push({ name: "ScanQr" });
    hideStatusBar;
  },

  data: () => ({
    drawer: false,
    pageTitle: "Home",
    mini: true,
    showBackButton: false,
    btnBack: false,
    pages: [
      {
        title: "Account",
        icon: "mdi-account-outline",
        to: "/profile",
        role: "all",
      },
      { title: "Home", icon: "mdi-view-dashboard", to: "/", role: "all" },
      {
        title: "Menu",
        icon: "mdi-silverware-fork-knife",
        to: "/dashboard/shopmenu",
        role: "shop",
      },
      {
        title: "Orders",
        icon: "mdi-basket-outline",
        to: "/dashboard/orders/live",
        role: "shop",
      },
      // { title: "Tickets", icon: "mdi-star", to: "/usertickets", role: "user" },
      {
        title: "Orders",
        icon: "mdi-basket-check-outline",
        to: "/orders",
        role: "user",
      },
    ],
  }),
  methods: {
    checkRoute() {
      if (!this.$store.state.isAuthenticated) {
        if (this.$route.name === "Login") return false;
        return true;
      }
      return true;
    },
    callWaiter() {
      window.Echo.channel(`Call_${this.$store.state.userId}`).listen(
        ".CallWaiter",
        (e) => {
          this.$notify({
            title: "Le serveur est appelé",
            message: e.message.message,
            duration: 0,
          });
        }
      );
    },
    shopOrders() {
      window.Echo.channel(`shop_${this.$store.state.userId}`).listen(
        ".CreateOrder",
        (e) => {
          let audio = new Audio(Sound);
          audio.play();
          this.$message({
            message: "nouvelle commande",
            type: "success",
          });
          this.$store.dispatch("setUserOrders");
        }
      );
    },
    async userOrders() {
      let user = await this.$axios.get("/user/me").then((response) => {
        return response.data;
      });

      // console.log(user);
      window.Echo.channel("user_" + user.user.id).listen(
        ".UpdateOrder",
        (e) => {
          if (e.order.order.order_status == "complete")
            this.$store.dispatch("setUserOrders");
          this.$notify({
            title: "Prompt",
            message:
              "Your order " +
              e.order.order.order_ref +
              " is at " +
              e.order.order.order_status +
              " status",
            type: "success",
            customClass: "bg-red-300",
            duration: 0,
          });
        }
      );
    },
    changePageStatus(item) {
      this.pageTitle = item.title;
      this.$router.go("/profile");
    },
    back() {
      console.log(this.$route.name);
      this.$router.go(-1);
    },

    async registerServiceWorker() {
      let subscribeToken = this.subscribeToken;
      if ("Notification" in window && navigator.serviceWorker) {
        registerSW(`${process.env.BASE_URL}firebase-messaging-sw.js`, {
          ready(reg) {
            console.log("Service worker is Ready");
            // subsctibe to FCM
            subscribeToken(reg);
          },
          registered(reg) {
            console.log("Service worker has been registered.");
            setInterval(() => {
              reg.update();
            }, 1000 * 60 * 30); // 30 min checks
          },
          cached() {
            // console.log('Content has been cached for offline use.');
          },
          updatefound() {
            console.log("New content is downloading.");
          },
          updated(reg) {
            console.log("New content is available; please refresh.");
            document.dispatchEvent(
              new CustomEvent("swUpdated", { detail: reg.waiting })
            );
          },
          offline() {
            console.log("No internet connection found.");
          },
          error(error) {
            console.error("Error during service worker registration:", error);
          },
        });
      }
    },
    async subscribeToken(reg) {
      let vapidKey =
        "BF37o06pjbiHZ55s1NRfJJ8lxKIrkwnKzCVoNJCtrTHWv3WbmeXx5ObAFrkGTtcRavEpHWFvlkWZTCu2gsRcwh8";
      try {
        let token = await getToken(this.messaging, {
          vapidKey,
          serviceWorkerRegistration: reg,
        });

        console.log("Client token => ", token);

        // send token to server
        let res = await this.sendTokenToServer(token);
        console.log("Sent to server", res.data);

        // store returned uuid with token
        this.setSubscribedToken(res.data);

        this.listenForegroundMessage(reg);
      } catch (err) {
        console.error(err);
        this.unsetSubscribedToken();
        console.log("Retry to subscribe");
      }
    },
    async unsubscribeToken() {
      try {
        let unsubscribe = await deleteToken(this.messaging);
        console.log("Subscribed  ", unsubscribe);
        let res = await this.removeTokenFromServer(this.clientToken.uuid);
        console.log("Unsubscribed from server", res.data);
        await this.unsetSubscribedToken();
      } catch (err) {
        console.error(err);
      }
    },
    async listenForegroundMessage(reg) {
      if (!reg)
        reg = await navigator.serviceWorker.getRegistration(
          `${process.env.BASE_URL}firebase-messaging-sw.js`
        );

      onMessage(this.messaging, (payload) => {
        console.log("Message received. ", payload);
        let { notification, data } = payload;
        let notificationTitle = "Test title";
        let notificationBody = "Test body";

        if (notification && notification.title && notification.body) {
          notificationTitle = notification.title;
          notificationBody = notification.body;
        } else if (data && data.title && data.body) {
          notificationTitle = data.title;
          notificationBody = data.body;
        }

        // in window noti
        this.$notify({
          group: "test",
          title: "[Foreground] " + notificationTitle,
          text: notificationBody,
          duration: true,
        });

        const notificationOptions = {
          body: notificationBody,
        };
        if (reg)
          reg.showNotification(
            "[Foreground] " + notificationTitle,
            notificationOptions
          );
      });
    },
    isSubscribed() {
      let ct = localStorage.getItem("clientToken");
      console.log("Subscribed", !!ct);
      return !!ct;
    },
    setSubscribedToken({ data }) {
      this.clientToken = data;
      console.log("stored in localstorage", this.clientToken);
      localStorage.setItem("clientToken", JSON.stringify(this.clientToken));
    },
    unsetSubscribedToken() {
      this.clientToken = null;
      localStorage.removeItem("clientToken");
      console.log("removed from localstorage", this.clientToken);
    },
    async getSubscribedToken() {
      let ct = localStorage.getItem("clientToken");

      if (ct) return JSON.parse(ct);
      else return null;
    },
    sendTokenToServer(token) {
      let dataObj = { token };
      if (this.clientToken && this.clientToken.uuid) {
        dataObj = { uuid: this.clientToken.uuid, token };
        console.log("before send to server", dataObj);
      }

      return this.$axios.post("/user/pushnotif", { fcm: token }).then((res) => {
        if (res.data.status)
          this.$message({
            message: "subscribed success",
            type: "success",
          });
        return res.data.status;
      });
    },
    removeTokenFromServer(uuid) {
      return axios.delete("http://localhost:3000/api/fcm/token/delete/" + uuid);
    },
    checkRole(item) {
      // console.log(item.role , item.role == 'all' || $store.state.role == item.role)
      if (item.role == "all" || this.$store.state.Role == item.role)
        return true;
      return false;
    },
  },
};
</script>

<style lang="scss">
.bg-color {
  background-color: #141b26;
}
</style>
