<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card color="#141B26" elevation="1">
          <v-img
            gradient="to top, rgba(0,0,0,.1), rgb(39, 83, 151,1)"
            min-height="130px"
            contain
          >
            <h1 class="text-center text-h5 time-queue">
              <span class="tw-text-4xl"> 6 </span> minutes d’attente
              <el-divider></el-divider>
            </h1>
          </v-img>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card color="#141B26" elevation="0">
          <v-list color="#141B26" elevation="0">
            <v-list-item v-for="(order, index) in ordersData" :key="index">
              <v-list-item-avatar size="66">
                <v-img
                  :src="
                    order.user != null
                      ? 'https://kanoa-api.win' + order.user.profile_image
                      : 'test'
                  "
                >
                </v-img>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>
                  {{
                    order.user
                      ? order.user.first_name + " " + order.user.last_name
                      : "User"
                  }}

                  <!-- test test -->
                  <span class="tw-text-sm tw-text-blue-600">
                    <timeago
                      :datetime="order.orderInfo.created_at"
                      :auto-update="60"
                    ></timeago>
                  </span>
                </v-list-item-title>

                <v-list-item-subtitle class="d-inline-flex">
                  <v-icon
                    small
                    v-for="(i, index) in order.OrderDetails[
                      Object.keys(order.OrderDetails)
                    ]"
                    :key="index"
                  >
                    mdi-cup
                  </v-icon>
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-avatar size="66">
                  <v-img src="https://i.ibb.co/9tFsyS3/Ellipse-2-5.png" fit>
                  </v-img>
                </v-avatar>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    ordersData: [],
  }),
  mounted() {
    this.$axios.get("/orders/1").then((res) => {
      // console.log(res)
      this.ordersData = res.data;
    });

    window.Echo.channel("order_1").listen(".UpdateOrder", (e) => {
      console.log("Updated order");
      this.$message({
        message: "Congrats, this is a success message.",
        type: "success",
      });

      this.$router.push("/events/1/orderdone/1");
      // if (e.test)
      // this.$router.push('/events/1/orderdone/12')
    });
  },
};
</script>

<style scoped>
.time-queue {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
</style>
