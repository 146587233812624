<template>
  <v-container>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-row align="center" justify="center">
      <v-col cols="12" align="center" justify="center">
        <el-upload
          :before-upload="beforeImage"
          :http-request="handleUploadAvatar"
          ref="upload"
          :show-file-list="false"
          :on-success="handleAvatarSuccess"
          action="#"
        >
          <v-avatar size="200" class="tw-relative">
            <!-- <v-avatar v-if="imageUrl" size="100"> -->
            <!-- <img
                        v-if="dialogProduct.productimageUrl"
                        :src="dialogProduct.productimageUrl"
                        class="rounded-xl avatar"
                      /> -->
            <!-- </v-avatar> -->
            <!-- <v-avatar  size="100"> -->
            <!-- <i class="el-icon-plus avatar-uploader-icon"></i> -->
            <!-- </v-avatar> -->
            <v-img :src="this.profile.profile_image"> </v-img>
            <div class="overlay-img-editor">Edit Image</div>
          </v-avatar>
        </el-upload>
      </v-col>
      <v-row align="center" justify="center" class="pa-2">
        <v-col class="my-1" cols="12" lg="5" md="5" sm="10">
          <v-text-field
            background-color="#29374E"
            outlined
            v-model="profile.first_name"
            label="Nom"
          >
          </v-text-field>
        </v-col>

        <v-col class="my-1" cols="12" lg="5" md="5" sm="10">
          <v-text-field
            background-color="#29374E"
            outlined
            label="Prenom"
            v-model="profile.last_name"
          >
          </v-text-field>
        </v-col>

        <v-col class="my-1" cols="12" lg="5" md="5" sm="10">
          <v-text-field
            background-color="#29374E"
            outlined
            label="Phone"
            v-model="profile.phone_number"
          >
          </v-text-field>
        </v-col>

        <v-col class="my-1" cols="12" lg="5" md="5" sm="10">
          <v-text-field
            background-color="#29374E"
            outlined
            label="Email"
            v-model="profile.email"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-btn color="blue" class="tw-w-1/2" @click="updateProfile()">Save</v-btn>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    loading: false,
    profile: {
      first_name: null,
      last_name: null,
      email: null,
      phone_number: null,
      profile_image: null,
      image_data: null,
    },
  }),
  mounted() {
    this.$axios.get("/user/me").then((res) => {
      console.log(res.data);
      this.profile = res.data.user;
      this.profile.profile_image =
        "https://kanoa-api.win" + res.data.user.profile_image;
    });
  },
  methods: {
    handleAvatarSuccess(res, file) {},
    async handleUploadAvatar(params) {
      this.loading = true;
      let file = params.file;
      let urrrl = URL.createObjectURL(file);
      this.profile.profile_image = urrrl;
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = function () {
        this.profile.image_data = reader.result;
        this.loading = false;
        return reader.result;
      }.bind(this);
    },
    beforeImage(file) {
      const isJPEG = file.type == "image/jpeg";
      const isJPG = file.type == "image/jpg";
      const isPNG = file.type == "image/png";
      const isWEBP = file.type == "image/webp";
      const isGIF = file.type == "image/gif";
      const isSVG = file.type == "image/svg+xml";
      const isLt2M = file.size / 1024 / 1024 < 3.5;

      if (!isPNG && !isJPEG && !isJPG && !isSVG && !isWEBP && !isGIF) {
        this.$message.error("Avatar picture must be an IMAGE!");
      }
      if (!isLt2M) {
        this.$message.error("Avatar picture size can not exceed 3,5MB!");
      }
      return isPNG || isJPEG || isJPG || isSVG || isWEBP || (isGIF && isLt2M);
    },
    updateProfile() {
      this.loading = true;

      this.$axios
        .put("/user/update", this.profile)
        .then((res) => {
          this.profile = res.data.user;
          this.$store.dispatch("updateUser", this.profile);
          this.profile.profile_image =
            "https://kanoa-api.win" + res.data.user.profile_image;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.overlay-img-editor {
  position: absolute;
  bottom: 0px;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5); /* Black see-through */
  color: #f1f1f1;
  color: white;
  height: 26%;
  width: 100%;
  font-size: 20px;
  text-align: center;
}
</style>
