import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import store from './store'
import vuetify from './plugins/vuetify'
import router from './router'
import ElementUI from 'element-ui';
import VueHorizontalList from 'vue-horizontal-list';
import './assets/css/tailwind.css'
import 'element-ui/lib/theme-chalk/index.css';
import VuePaycard from 'vue-paycard'
import axios from './plugins/customAxios.js';
import VueMeta from 'vue-meta'
import iosSafeArea from 'vue-ios-safe-area'
import Echo from 'laravel-echo'
import VueTimeago from 'vue-timeago'
import VueQr from 'vue-qr'
import VueSocialSharing from 'vue-social-sharing'
import VueCountdown from '@chenfengyuan/vue-countdown';
import JwPagination from 'jw-vue-pagination';
import VueQrcodeReader from "vue-qrcode-reader";

Vue.component('jw-pagination', JwPagination);
Vue.component(VueCountdown.name, VueCountdown);
Vue.use(VueQrcodeReader);

Vue.use(VueSocialSharing);
Vue.use(VueTimeago, {
  name: 'Timeago', // Component name, `Timeago` by default
  locale: 'fr', // Default locale
  // We use `date-fns` under the hood
  // So you can use all locales from it
  locales: {
    'zh-CN': require('date-fns/locale/zh_cn'),
    ja: require('date-fns/locale/ja')
  }
})
Vue.use(VueQr)





window.Pusher = require('pusher-js')

// This assumes you have already saved your bearer token in your browsers local storage
const token = localStorage.getItem('user-token')

window.Echo = new Echo({
  broadcaster: 'pusher',
  key: 'websocketkey',
  wsHost: 'kanoa-api.win',
  forceTLS: false,
  wsPort: 6001,
  wssPort: 6001,
  disableStats: true,
  enabledTransports: ['ws', 'wss'],
})

Vue.use(iosSafeArea)
Vue.use(VueMeta)
// import moment from 'vue-moment';

const moment = require('moment')
require('moment/locale/fr')

Vue.use(require('vue-moment'), {
    moment
})

Vue.use(VuePaycard)
window.axios = require('axios')

Vue.use(require('vue-cookies'))
Vue.$cookies.config('3d')
Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(VueHorizontalList);

new Vue({
  store,
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
