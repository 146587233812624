<template>
<v-container>
  <v-card id="account-setting-card" flat>
    <!-- tabs -->
    <v-tabs
    centered
    background-color="#141B26"
    active-class="active-bg tw-text-white"
      v-model="tab"
      show-arrows
    >
      <v-tab
      class="rounded-lg"
      
        v-for="tab in tabs"
        :key="tab.icon"
        @click="changetab(tab)"
      >
      <button  type="button" class="tw-rounded-lg tw-inline-block tw-px-6 tw-py-2.5  tw-text-white tw-font-medium tw-text-xs tw-leading-tight tw-uppercase tw-hover:bg-blue-700 tw-focus:bg-blue-700 tw-focus:outline-none tw-focus:ring-0 tw-active:bg-blue-800 tw-transition tw-duration-150 tw-ease-in-out">{{ tab.title }}</button>
      </v-tab>

      
    </v-tabs>

    <!-- tabs item -->
    <v-tabs-items touchless v-model="tab" class="bg-darke">
      <v-tab-item>
        <preparer :change="change" ></preparer>
      </v-tab-item>

      <v-tab-item>
        <attente :change="change"></attente>
      </v-tab-item>

      <v-tab-item>
        <termines :change="change" ></termines>
      </v-tab-item>

    </v-tabs-items>
  </v-card>
</v-container>
</template>

<script>

// demos
import Preparer from './Preparer.vue'
import Attente from './Attente.vue'
import Termines from './Termines.vue'

export default {
  components: {
    Preparer,
    Attente,
    Termines,
  },

  data : () => ({
    tab: '',
    change: '',
    tabs: [
      { title: 'À PRÉPARER'},
      { title: 'EN ATTENTE'},
      { title: 'TERMINÉES'},
    ],
  }),

  methods: {
    changetab(tab) {
      this.change = tab
      setTimeout(() => this.change = '', 100)
    }
  }
}
</script>

<style scoped>

.active-bg{
  background-color: #FF005C;
}
.bg-darke{
    background-color: #0D131C;
}
.v-tabs-bar__content {
  flex-wrap: wrap;
  width: 100%;
}
div.v-tabs-bar {
  height: auto;
}
</style>
