import Vue from "vue";
import Vuex from "vuex";
import axios from '../plugins/customAxios.js';

Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    expiration : Date.now() + 3600 * 1000,
    userName: localStorage.getItem("userName"),
    userId: localStorage.getItem("userId"),
    userImage: localStorage.getItem("userImage"),
    table: localStorage.getItem("table"),
    shop: localStorage.getItem("shop"),
    userEmail: localStorage.getItem("userEmail"),
    isAuthenticated: !!localStorage.getItem("isAuthenticated"),
    Role: localStorage.getItem("role"),
    Token: localStorage.getItem("token"),
    userOrders: (localStorage.getItem("userOrders")) ? JSON.parse(localStorage.getItem("userOrders")) : [],
    shopOrders: (localStorage.getItem("shopOrders")) ? JSON.parse(localStorage.getItem("shopOrders")) : []
  },
  mutations: {
    SET_USER_DATA(state, userData) {
      state.user = userData;
      (state.isAuthenticated = true),
        localStorage.setItem("userName", `${userData.user.first_name} ${userData.user.last_name}`);
      localStorage.setItem("userEmail", userData.user.email);
      localStorage.setItem("userId", userData.user.id);
      localStorage.setItem("userImage", userData.user.profile_image);
      localStorage.setItem("isAuthenticated", true);
      localStorage.setItem("role", userData.user.role);
      localStorage.setItem("token", userData.accessToken);
    },
    LOGOUT(state) {
      state.user = null;
      state.isAuthenticated = false,
      localStorage.removeItem("userId");
      localStorage.removeItem("isAuthenticated");
      localStorage.removeItem("role");
      localStorage.removeItem("token");
    },
    UPDATE_USER(state, userData) {
      state.user = userData;
      (state.isAuthenticated = true),
      localStorage.setItem("userName", userData.user.name);
      localStorage.setItem("userId", userData.user.id);
      localStorage.setItem("userEmail", userData.user.email);
      localStorage.setItem("isAuthenticated", true);
      localStorage.setItem("role", userData.user.role);
    },
    SET_USER_ORDERS(state, currentOrders) {
      state.userOrders = currentOrders
      localStorage.removeItem("userOrders");
      localStorage.setItem("userOrders", JSON.stringify(currentOrders))
    },
    REMOVE_USER_ORDER(state, currentOrders) {
      state.userOrders = currentOrders
      localStorage.removeItem("userOrders");
    },
    SET_SHOP_ORDERS(state, currentOrders) {
      state.shopOrders = currentOrders
      localStorage.setItem("shopOrders", JSON.stringify(currentOrders))
    },
    REMOVE_SHOP_ORDER(state, currentOrders) {
      state.shopOrders = currentOrders
      localStorage.removeItem("shopOrders");
    },
    SET_TABLE_USER(state, table) {
      state.table = table
      localStorage.setItem('table' , table, state.expiration)
    },
    SET_SHOP_USER(state, shop) {
      state.shop = shop
      localStorage.setItem('shop' , shop, state.expiration)
    },
    REMOVE_TABLE_USER(state) {
      state.table = ''
      localStorage.removeItem('table')
    },
    REMOVE_SHOP_USER(state) {
      state.shop = ''
      localStorage.removeItem('shop')
    }
    
  },
  actions: {
    login({ commit }, credentials) {
      return axios
        .post("https://kanoa-api.win/api/user/login", credentials)
        .then(({ data }) => {
          if (data)
            commit("SET_USER_DATA", data)
        })
        .catch((e) => {
          console.log(e)
        });
    },
    logout({ commit }) {
      return axios.post('https://kanoa-api.win/api/user/logout',)
      .then((response) => {

        if (response.data.status)
          commit("LOGOUT");
          commit("REMOVE_SHOP_ORDER")
          commit("REMOVE_USER_ORDER")
          location.reload();

      })
      .catch((e) => {
        console.log(e)
      });
    },
    updateUser({ commit }, userData) {
      commit("UPDATE_USER", userData);
    },
    setUserOrders({ commit }) {
      return axios.get("/userorders").then((res) => {
        let orders = res.data;
        orders.unshift({ header: "Mes commandes" });
        commit("SET_USER_ORDERS" , orders)
      });
    },
    removeUserOrder({ commit }, currentOrders) {
      commit("REMOVE_USER_ORDER" , currentOrders)
    },
    setShopOrders({ commit }, currentOrders) {
      commit("SET_SHOP_ORDERS" , currentOrders)
    },
    removeShopOrder({ commit }, currentOrders) {
      commit("REMOVE_SHOP_ORDER" , currentOrders)
    },
    addTableUser({ commit }, table) {
      commit("SET_TABLE_USER" , table)
    },
    addShopUser({ commit }, shop) {
      commit("SET_SHOP_USER" , shop)
    },
    removeShopUser({ commit }) {
      commit("REMOVE_SHOP_USER")
    },
    removeTableUser({ commit }) {
      commit("REMOVE_TABLE_USER")
    }

  },
  modules: {},
  getters: {
    isUserLoggedIn(state) {
      return !!state.isAuthenticated;
    },
  },
});