import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import ScanQr from '../views/ScanQr.vue'
import Register from '../views/Registration.vue'
import Login from '../views/Login.vue'
import Event from '../views/events/Event.vue'
import Tickets from '../views/events/Tickets.vue'
import Menu from '../views/events/Menu.vue'
import Cart from '../views/events/Cart.vue'
import TicketsCart from '../views/events/TicketsCart.vue'
import Waitlist from '../views/events/Waitlist.vue'
import Table from '../views/events/Table.vue'
import Orderdone from '../views/events/Orderdone.vue'
import Profile from '../views/Profile.vue'
import Orders from '../views/Orders.vue'
import TicketsUser from '../views/TicketsUser.vue'
import MenuRestaurant from '../views/dashboard/Menu.vue'
import ShopOrders from '../views/dashboard/Orders.vue'
import Stats from '../views/dashboard/home.vue'
import Shop from '../views/dashboard/Shop.vue'
import Liveorders from '../views/dashboard/orders/Live.vue'
import store from '../store/index.js'





Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: (to, from, next) => {
      const Role = store.state.Role;
      
      if(!Role || Role == 'user') next('/scan')
      else
        next('/dashboard/orders/live')
     }
  },
  {

    path: '/place/:id/:table',
    name: 'Home',
    component: Home,
    beforeEnter: (to, from, next) => {
      const Role = store.state.Role;
      
      if(!Role || Role == 'user') next()
      else
        next('/')
     }
  },
  {
    path: '/scan',
    name: 'ScanQr',
    component: ScanQr,
    beforeEnter: (to, from, next) => {
      const Role = store.state.Role;
      
      if(!Role || Role == 'user') next()
      else
        next('/')
     }
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/events/:id',
    name: 'Event',
    component: Event,
    beforeEnter: (to, from, next) => {
      const Role = store.state.Role;
      if(Role == 'user') next()
      else
        next('/')
     }
  },
  {
    path: '/events/:id/tickets',
    name: 'Tickets',
    component: Tickets
  },
  {
    path: '/:id/menu',
    name: 'Menu',
    component: Menu,
    beforeEnter: (to, from, next) => {
      const Role = store.state.Role;
      if(!Role || Role == 'user') next()
      else
        next('/')
     }
  },
  {
    path: '/:id/cart',
    name: 'Cart',
    component: Cart,
    beforeEnter: (to, from, next) => {
      const Role = store.state.Role;
      if(Role == 'user') next()
      else
        next('/')
     }
  },
  {
    path: '/events/:id/ticketcart',
    name: 'TicketsCart',
    component: TicketsCart
  },
  {
    path: '/events/:id/table',
    name: 'Table',
    component: Table,
    beforeEnter: (to, from, next) => {
      const Role = store.state.Role;
      if(Role == 'user') next()
      else
        next('/')
     }
  },
  {
    path: '/events/:id/waitlist',
    name: 'Waitlist',
    component: Waitlist,
    beforeEnter: (to, from, next) => {
      const Role = store.state.Role;
      if(Role == 'user') next()
      else
        next('/')
     }
  },
  {
    path: '/events/orderdone/:id',
    name: 'orderdone',
    component: Orderdone,
    beforeEnter: (to, from, next) => {
      const Role = store.state.Role;
      if(Role == 'user') next()
      else
        next('/')
     }
  }
  ,
  {
    path: '/profile',
    name: 'profile',
    component: Profile
  },
  {
    path: '/orders',
    name: 'orders',
    component: Orders
  },
  {
    path: '/dashboard/stats',
    name: 'stats',
    component: Stats,
    beforeEnter: (to, from, next) => {
      const Role = store.state.Role;
      if(Role == 'shop') next()
      else
        next('/')
     }
  },
  {
    path: '/dashboard/shop',
    name: 'shop',
    component: Shop,
    beforeEnter: (to, from, next) => {
      const Role = store.state.Role;
      if(Role == 'shop') next()
      else
        next('/')
     }
  },
  {
    path: '/dashboard/shopmenu',
    name: 'menuShop',
    component: MenuRestaurant,
    beforeEnter: (to, from, next) => {
      const Role = store.state.Role;
      if(Role == 'shop') next()
      else
        next('/')
     }
  },
  {
    path: '/usertickets',
    name: 'usertickets',
    component: TicketsUser
  },
  {
    path: '/dashboard/orders',
    name: 'shoporders',
    component: ShopOrders,
    beforeEnter: (to, from, next) => {
      const studentClass = store.state.Role;
      if(studentClass == 'shop') next()
      else
        next('/')
     }
  },
  {
    path: '/dashboard/orders/live',
    name: 'liveorders',
    component: Liveorders,
    beforeEnter: (to, from, next) => {
      const studentClass = store.state.Role;
      if(studentClass == 'shop') next()
      else
        next('/')
     }
  }
]

const router = new VueRouter({
  routes,
})

router.beforeEach((to, from, next) => {

  const isAuthenticated = store.state.isAuthenticated;

  if (to.name === 'Home' || to.name === 'Menu' || to.name === 'ScanQr')
  {
    next();
  }
  else if (to.name === 'Login' && isAuthenticated) {
    next({name: 'Home'})
  }

  else if (to.name === 'Register' && isAuthenticated) {
    next({name: 'Home'})
  }

  else if (to.name !== 'Login' && !isAuthenticated)
  {
    if (to.name !== 'Register')
      next({ name: 'Login' })
    next()
  }
  // // if the user is not authenticated, `next` is called twice
  else {next()}

})

export default router
