<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card elevation="0" class="auth-card" color="#0D131C">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-0">
          <router-link to="/" class="d-flex align-center">
            <v-img
              :src="require('@/assets/LogoTakin.png')"
              max-height="130px"
              max-width="150px"
              height="130px"
              width="130px"
              alt="logo"
              contain
              class="me-0"
            ></v-img>
          </router-link>
        </v-card-title>
        <v-card-title class="d-flex align-center justify-center mb-3">
          <h2 class="text-h3 font-weight-bold">Takin</h2>
        </v-card-title>

        <!-- title -->
        <!-- <v-card-text>
          <p class="text-2xl font-weight-semibold text--primary mb-2">
            Adventure starts here 🚀
          </p>
          <p class="mb-2">
            Make your app management easy and fun!
          </p>
        </v-card-text> -->

        <!-- login form -->
        <v-card-text>
          <v-form v-model="valid">
            <v-text-field
              v-model="email"
              outlined
              :rules="emailRules"
              label="Email"
              placeholder="ahmed.yassine@example.com"
              class="mb-3"
            ></v-text-field>
            <div class="tw-flex tw-space-x-2">
              <v-text-field
                v-model="first_name"
                outlined
                :rules="first_nameRules"
                label="Nom"
                placeholder="Claude"
                class="tw-flex-1"
              ></v-text-field>
              <v-text-field
                v-model="last_name"
                outlined
                :rules="last_nameRules"
                label="Prenom"
                placeholder="Felix"
                class="tw-flex-1"
              ></v-text-field>
            </div>
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="birthdate"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="birthdate"
                  outlined
                  label="Birthdate"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="birthdate" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false">
                  Annuler
                </v-btn>
                <v-btn text color="primary" @click="$refs.menu.save(birthdate)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>

            <v-text-field
              v-model="password"
              outlined
              counter
              :rules="passwordRules"
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Password"
              placeholder="············"
              :append-icon="
                isPasswordVisible ? 'mdi-eye-off-outline' : 'mdi-eye-outline'
              "
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>

            <v-text-field
              v-model="passwordConfirm"
              outlined
              counter
              :rules="[matchingPasswords]"
              :type="isPasswordVisible ? 'text' : 'password'"
              label="Confirm Password"
              placeholder="············"
              :append-icon="
                isPasswordVisible ? 'mdi-eye-off-outline' : 'mdi-eye-outline'
              "
              @click:append="isPasswordVisible = !isPasswordVisible"
            ></v-text-field>

            <v-checkbox :rules="checkboxRules" hide-details="" class="mt-1">
              <template #label>
                <div class="d-flex align-center flex-wrap tw-inline">
                  <span class="me-2">J'accepte </span
                  ><a href="javascript:void(0)"
                    >politique de confidentialité &amp; conditions</a
                  >
                </div>
              </template>
            </v-checkbox>

            <v-btn
              block
              color="primary"
              class="mt-6"
              :loading="loadingButton"
              :disabled="!valid"
              @click="signUp()"
            >
              Sign Up
            </v-btn>
          </v-form>
        </v-card-text>

        <!-- create new account  -->
        <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
          <span class="me-2"> Vous avez déjà un compte ? </span>
          <router-link :to="{ name: 'Login' }"> Connectez-vous </router-link>
        </v-card-text>

        <!-- divider -->
        <!-- <v-card-text class="d-flex align-center mt-2">
          <v-divider></v-divider>
          <span class="mx-5">or</span>
          <v-divider></v-divider>
        </v-card-text> -->

        <!-- social link -->
        <!-- <v-card-actions class="d-flex justify-center">
          <v-btn
            v-for="link in socialLink"
            :key="link.icon"
            icon
            class="ms-1"
          >
            <v-icon :color="$vuetify.theme.dark ? link.colorInDark : link.color">
              {{ link.icon }}
            </v-icon>
          </v-btn>
        </v-card-actions> -->
      </v-card>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiFacebook,
  mdiTwitter,
  mdiGithub,
  mdiGoogle,
  mdiEyeOutline,
  mdiEyeOffOutline,
} from "@mdi/js";
import { ref } from "@vue/composition-api";

export default {
  data: () => ({
    password: "",
    passwordConfirm: "",
    first_nameRules: [(v) => !!v || "Nom is required"],
    last_nameRules: [(v) => !!v || "Prenom is required"],
    passwordRules: [
      (v) => !!v || "Password is required",
      (v) => v.length >= 8 || "Password must be more than 8 characters",
    ],
    slugRules: [
      (v) => !!v || "Slug is required",
      (v) =>
        /^[^<>/./\   '"\\?!%~`,=$@&*(){}+#]+$/.test(v) ||
        "Slug must contain only _ or -",
    ],
    checkboxRules: [
      (v) => !!v || "You should accept our terms and conditions.",
    ],
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+/.test(v) || "E-mail must be valid",
    ],
    valid: false,
    slug: null,
    email: "",
    loadingButton: false,
    successSlug: false,
    first_name: null,
    last_name: null,
    showLoading: false,
    errorMessage: null,
    successMessage: null,
    errorSlug: false,
    birthdate: null,
    menu: false,
    isPasswordVisible: false,
    icons: {
      mdiEyeOutline,
      mdiEyeOffOutline,
    },
  }),
  mounted() {
    //   console.log(this.socialLink.length)
  },
  methods: {
    signUp() {
      this.loadingButton = true;
      let user = {
        first_name: this.first_name,
        last_name: this.last_name,
        email: this.email,
        password: this.password,
        birthdate: this.birthdate,
      };
      this.$axios.post("/user/add", user).then((res) => {
        if (res.data.status) {
          this.$message({
            message: res.data.message,
            type: "success",
          });
          setTimeout(() => this.$router.push("/login"), 2000);
        }
        this.loadingButton = false;
      });
    },
    matchingPasswords: function () {
      if (this.password === this.passwordConfirm) {
        return true;
      } else {
        return "Passwords does not match.";
      }
    },
  },
};
</script>

<style lang="scss">
@import "~@/plugins/vuetify/default-preset/preset/pages/auth.scss";
</style>
