<template>
  <div>
    <v-btn class="" @click="goBack" icon>
      <v-icon large>mdi-chevron-left</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  methods: {
    goBack() {
      const currentDomain = window.location.origin;
      let referrerDomain = "";

      if (document.referrer) {
        try {
          referrerDomain = new URL(document.referrer).origin;
        } catch (error) {
          console.error(error);
          referrerDomain = "";
        }
      }

      console.log(this.$router.go(-1));

      if (referrerDomain === currentDomain) {
        this.$router.go(-1);
      } else {
        this.$router.push("/");
      }
    },
  },
};
</script>
