<template>


<v-container class="">
<v-row class="px-lg-2 px-md-0 py-5">
  <v-progress-linear
            color="deep-purple accent-4"
            indeterminate
            :active="isFetching"
            rounded
            height="6"
          ></v-progress-linear>
  <v-overlay :value="isFetching">
    
  </v-overlay>
        <v-col md="3" sm='4' v-if="!isFetching">
          <DataCard class="rounded-lg" Members="Payments" cardTitle="Gains du mois" cardData="2000$" cardColor="#23D17E" btncolor="green lighten-4" ButtonIcon="mdi-cash-multiple"/>
        </v-col>
        <v-col md="3" sm='4' v-if="!isFetching">
          <DataCard class="rounded-lg" Members="Payments" cardTitle="Total Gains" cardData="12000$" cardColor="#23D17E" btncolor="green lighten-4" ButtonIcon="mdi-currency-usd"/>
        </v-col>
        <v-col
          md="3" sm='4'
          class="mx-auto"
          v-if="!isFetching"
        >
        <DataCard class="rounded-lg" Members="Charges" cardTitle="Charges" cardData="1200$" btncolor="red lighten-4" cardColor="#F87171" ButtonIcon="mdi-cash-remove"/>
        </v-col>
        <v-col
          md="3" sm='4'
          class="ml-auto"
          v-if="!isFetching"
        >
        <DataCard class="rounded-lg" Members="Members" cardTitle="Visites" cardData="120" btncolor="#0063FF" cardColor="#60A5FA" ButtonIcon="mdi-chart-timeline-variant-shimmer"/>

        </v-col>
      </v-row>

<v-row>

    <!--  -->
</v-row>
</v-container>


</template>

<script>
import DataCard from '../../components/Card.vue'


  export default {
    name: 'Dashboard',
    
    components: {
      DataCard
    },
    data: () => ({
    isFetching: false,
    payments: [],
    date: new Date(),
    expand: false,
    gains: '',
    charges: '',
    netProfit: '',
    membersTotal: '',
    justify: [
        'start',
        'center',
        'end',
      ],
    }),   
  }
</script>