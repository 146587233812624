<template>
  <div id="adyen-dropin" style="overflow: hidden"></div>
</template>

<script>
import paymentMethodsMock from "../assets/paymentsMethodsMock.json";

const ADYEN_JS_URL =
  "https://checkoutshopper-test.adyen.com/checkoutshopper/sdk/3.1.0/adyen.js";
const ADYEN_CSS_URL =
  "https://checkoutshopper-test.adyen.com/checkoutshopper/sdk/3.1.0/adyen.css";

export default {
  name: "AdyenDropin",
  props: ["amount", "type"],
  data: () => ({
    session: {},
  }),
  methods: {
    initAdyenCheckout() {
      const configuration = {
        locale: "en_US",
        environment: "test",
        originKey:
          "pub.v2.8216468345851144.aHR0cDovL2xvY2FsaG9zdDo4MDgw.TqUiSoSleqMyaqExmA-oI9jMm87i7D4F45p3812hgGM",
        paymentMethodsResponse: paymentMethodsMock,
        session: {
          id: this.session.id,
          sessionData: this.session.sessionData,
        },
        amount: {
          value: this.amount * 100,
          currency: "EUR",
        },
        onSubmit: (state, dropin) => {
          let payData = {
            amount: this.amount * 100,
            card: state.data,
            tickets: JSON.parse(localStorage.getItem("ticketsCart")),
          };

          let orderData = {
            amount: this.amount * 100,
            card: state.data,
            items: JSON.parse(localStorage.getItem("cart")),
            payment_type: "credit_card",
          };
          dropin.setStatus("loading");
          if (this.type == "ticket")
            this.$axios
              .post("/pay", payData)
              .then((res) => {
                dropin.setStatus("done");
                this.$emit("done");
                this.onPaymentCompleted(res.data);
              })
              .catch((err) => {
                dropin.setStatus("done");
                console.log(res);
                this.onPaymentCompleted(res.data);
              });
          else
            this.$axios
              .post("/payorder", orderData)
              .then((res) => {
                dropin.setStatus("done");
                this.$emit("done");

                this.onPaymentCompleted(res.data);
              })
              .catch((err) => {
                dropin.setStatus("done");
                console.log(res);
                this.onPaymentCompleted(res.data);
              });
          // makePaymentCall(state.data).then...
        },
        onAdditionalDetails: (state, dropin) => {
          // makeDetailsCall(state.data).then...
        },
      };

      // You can add AdyenCheckout to your list of globals and then delete the window reference:
      // const checkout = new AdyenCheckout(configuration);
      const checkout = new window.AdyenCheckout(configuration);

      // If you need to refer to the dropin externaly, you can save this inside a variable:
      // const dropin = checkout.create...
      checkout.create("dropin").mount("#adyen-dropin");
    },

    onPaymentCompleted(result) {
      console.log(result);
      if (result.status) {
        this.$message({
          message: "Congrats, this is a success message.",
          type: "success",
        });
        this.$emit("status", 1);
      } else {
        this.$message.error(result.message);
        this.$emit("status", 0);
      }
    },
  },
  mounted() {
    this.$axios
      .post("/checkout", { amount: this.amount * 100 })
      .then((result) => {
        this.session = result.data;
        console.log(result);
      });
    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.href = ADYEN_CSS_URL;
    document.head.appendChild(link);

    const script = document.createElement("script");
    script.src = ADYEN_JS_URL;
    script.async = true;
    script.onload = this.initAdyenCheckout; // Wait until the script is loaded before initiating AdyenCheckout
    document.body.appendChild(script);
  },
};
</script>
