<template>
  <v-card
    class="mx-auto"
    max-width="400"
    ref="capture"
    :id="order_ref"
    :disabled="used"
  >
    <v-img
      class="white--text align-end"
      height="200px"
      :src="!used ? event_image : imgUsed"
      gradient="to top, rgba(0,0,0,.1), rgb(39, 83, 151,0.5)"
    >
      <v-card-title class="tw-w-1/2 mx-2 pa-0">
        {{ shop_name }}
      </v-card-title>
      <v-chip class="ma-2" small>{{ date }}</v-chip>
    </v-img>
    <v-card-title class="pb-0"
      >{{ event_name }} <v-spacer></v-spacer>
    </v-card-title>
    <v-card-text class="text--primary tw-grid tw-grid-cols-2 tw-gap-2">
      <p class="my-auto tw-block tw-place-self-start">
        <vue-show-more-text :text="desc" :lines="2" />
      </p>
      <vue-qr
        class="tw-rounded-xl tw-place-self-end my-auto"
        :correctLevel="3"
        :logoSrc="src2"
        :margin="5"
        :logoScale="0.3"
        :components="qrComponents"
        :dotScale="0.6"
        colorLight="#000000"
        colorDark="#00A2FF"
        :text="!used ? order_ref : 'Invalid'"
        :size="100"
        :whiteMargin="false"
      ></vue-qr>
    </v-card-text>
  </v-card>
</template>

<script>
import vueShowMoreText from "vue-show-more-text";
import domtoimage from "dom-to-image-more";

export default {
  components: {
    vueShowMoreText,
  },
  props: {
    used: {
      type: Boolean,
      default: false,
    },
    shop_name: {
      type: String,
      default: "",
    },
    event_name: {
      type: String,
      default: "",
    },
    date: {
      type: String,
      default: "",
    },
    order_ref: {
      type: String,
      default: "",
    },
    event_image: {
      type: String,
      default: "",
    },
    desc: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    qrComponents: {
      data: {
        scale: 0.8,
      },
      timing: {
        scale: 8,
        protectors: false,
      },
      alignment: {
        scale: 2,
        protectors: false,
      },
      cornerAlignment: {
        scale: 1,
        protectors: false,
      },
    },
    src2: require("@/assets/LogoTakin.png"),
    src: require("@/assets/LogoTakin.png"),
    imgUsed: require("@/assets/used-bg.png"),
    tickets: [],
    fab: false,
    networks: [
      { network: "email", name: "Email", icon: "at", color: "#333333" },
      {
        network: "facebook",
        name: "Facebook",
        icon: "facebook",
        color: "#1877f2",
      },
      {
        network: "linkedin",
        name: "LinkedIn",
        icon: "linkedin",
        color: "#007bb5",
      },
      {
        network: "messenger",
        name: "Messenger",
        icon: "facebook-messenger",
        color: "#0084ff",
      },
      {
        network: "sms",
        name: "SMS",
        icon: "message-processing",
        color: "#333333",
      },
      {
        network: "telegram",
        name: "Telegram",
        icon: "send-circle",
        color: "#0088cc",
      },
      {
        network: "whatsapp",
        name: "Whatsapp",
        icon: "whatsapp",
        color: "#25d366",
      },
    ],
    sharing: {
      url: "",
      title: "I want to share with you an event ticket",
      description: "",
    },
  }),
  methods: {
    open(elem) {
      const prtHtml = document.getElementById(elem).innerHTML;

      // Get all stylesheets HTML
      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }

      // Open the print window
      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );

      WinPrint.document.write(`<!DOCTYPE html>
<html>
  <head>
    ${stylesHtml}
  </head>
  <body>
    ${prtHtml}
  </body>
</html>`);

      wait(2000);
      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      // WinPrint.close();
    },
  },
};
</script>
