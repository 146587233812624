<template>
  <v-container fluid>
    <v-bottom-sheet
      color="#131C28"
      v-model="cart"
      scrollable
      transition="bottom-sheet-transition"
    >
      <v-card max-height="400px" color="#131C28">
        <v-divider></v-divider>
        <v-card-text>
          <v-list color="#131C28">
            <v-list-item v-for="item in ticketShop" :key="item.id" class="my-2">
              <!-- <v-list-item-icon>
          <v-icon
            v-if="item.icon"
            color="pink"
          >
            mdi-star
          </v-icon>
        </v-list-item-icon> -->

              <v-list-item-content>
                <v-list-item-title v-text="item.name"></v-list-item-title>
              </v-list-item-content>

              <v-btn icon @click="minusQuantity(item)"
                ><v-icon>mdi-minus</v-icon></v-btn
              >
              <v-list-item-avatar class="ma-0">
                {{ item.quantity }}
              </v-list-item-avatar>
              <v-btn icon @click="plusQuantity(item)"
                ><v-icon>mdi-plus</v-icon></v-btn
              >
            </v-list-item>
            <v-divider></v-divider>
          </v-list>
        </v-card-text>

        <v-card-actions>
          <v-btn
            :to="'/events/' + $route.params.id + '/ticketcart'"
            class="tw-w-full"
            color="#67C9FF"
          >
            Confirm Order
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-bottom-sheet>
    <v-speed-dial
      v-if="!disabled"
      v-model="fab"
      bottom
      fixed
      right
      direction="top"
      transition="slide-y-reverse-transition"
    >
      <template v-slot:activator>
        <v-badge
          bordered
          :content="quantityTickets"
          :value="quantityTickets"
          overlap
          color="green"
        >
          <v-btn
            v-model="fab"
            color="blue darken-2"
            dark
            @click="cart = true"
            block
          >
            Tickets
          </v-btn>
        </v-badge>
      </template>
    </v-speed-dial>
    <v-row class="pa-0 mt-3" align="center" justify="center">
      <slot v-if="tickets.length > 0">
        <v-col
          v-for="ticket in tickets"
          :key="ticket.id"
          class="py-2"
          cols="12"
          lg="4"
          md="6"
          sm="10"
        >
          <v-card class="pa-0 rounded-lg" color="#29374E">
            <v-card-text class="d-flex pl-2 justify-space-between">
              <v-col cols="6" class="pa-0 mt-2">
                <span class="tw-block tw-text-lg ma-2 white--text tw-font-bold">
                  {{ ticket.name }}
                </span>
                <span class="text text-h5 font-weight-bold tw-block ma-2 px-2">
                  {{ ticket.price }} $
                </span>
              </v-col>
              <v-col
                cols="auto"
                class="tw-inline-block tw-align-middle my-auto"
                align="center"
                justify="center"
              >
                <v-btn
                  icon
                  v-if="ticketShop.find((x) => x.id === ticket.id)"
                  @click="
                    minusQuantity(ticketShop.find((x) => x.id === ticket.id))
                  "
                  :disabled="disabled"
                >
                  <v-icon>mdi-minus</v-icon>
                </v-btn>
                <span class="tw-text-lg">
                  {{
                    ticketShop.find((x) => x.id === ticket.id)
                      ? ticketShop.find((x) => x.id === ticket.id).quantity
                      : 0
                  }}
                </span>
                <v-btn icon @click="plusquantity(ticket)">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-card-text>
          </v-card>
        </v-col>
      </slot>
      <el-empty
        v-else
        :image-size="200"
        description="Pas de ticket pour le moment"
      ></el-empty>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    quantity: 0,
    fab: false,
    cart: false,
    tickets: [],
    ticketShop: [],
    quantityTickets: 0,
    // disabled: null,
  }),

  mounted() {
    this.ticketShop = JSON.parse(localStorage.getItem("ticketsCart"));
    console.log(this.ticketShop);
    if (!this.ticketShop) this.ticketShop = [];
    for (let i = 0; i < this.ticketShop.length; i++) {
      this.quantityTickets += this.ticketShop[i].quantity;
    }

    this.$axios.get("/tickets/" + this.$route.params.id).then((data) => {
      this.tickets = data.data;
      console.log(data);
    });
  },
  computed: {
    disabled: function () {
      if (this.quantityTickets > 0) return false;
      return true;
    },
    // cart: () => {
    //   if (this.quantityTickets > 0)
    //     return true;
    //         return false;
    // }
  },
  methods: {
    minusQuantity(item) {
      item.quantity -= 1;
      this.quantityTickets -= 1;
      if (item.quantity <= 0) {
        let index = this.ticketShop
          .map(function (e) {
            return e.id;
          })
          .indexOf(item.id);
        this.ticketShop.splice(index, 1);
        this.cart = false;
      }
      if (localStorage.getItem("ticketsCart") === null)
        localStorage.setItem("ticketsCart", JSON.stringify(this.ticketShop));
      else {
        localStorage.removeItem("ticketsCart");
        localStorage.setItem("ticketsCart", JSON.stringify(this.ticketShop));
      }
    },
    plusQuantity(item) {
      item.quantity += 1;
      this.quantityTickets += 1;
      if (localStorage.getItem("ticketsCart") === null)
        localStorage.setItem("ticketsCart", JSON.stringify(this.ticketShop));
      else {
        localStorage.removeItem("ticketsCart");
        localStorage.setItem("ticketsCart", JSON.stringify(this.ticketShop));
      }
    },
    plusquantity(item) {
      let itemDetails = {
        id: item.id,
        name: item.name,
        price: item.price,
        quantity: 1,
      };
      if (
        this.ticketShop.filter(function (e) {
          return e.id === item.id;
        }).length > 0
      ) {
        // console.log(this.ticketShop.map(function(e) { return e.id; }).indexOf(item.id))
        let index = this.ticketShop
          .map(function (e) {
            return e.id;
          })
          .indexOf(item.id);
        this.ticketShop[index].quantity += 1;
      } else this.ticketShop.push(itemDetails);
      this.quantityTickets += 1;
      if (localStorage.getItem("ticketsCart") === null)
        localStorage.setItem("ticketsCart", JSON.stringify(this.ticketShop));
      else {
        localStorage.removeItem("ticketsCart");
        localStorage.setItem("ticketsCart", JSON.stringify(this.ticketShop));
      }
    },
  },
};
</script>

<style scoped>
.text {
  color: #60c6ff;
}
.v-btn--example {
  bottom: 0;
  position: absolute;
  margin: 0 0 16px 16px;
}
</style>
