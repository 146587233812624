<template>
  <v-container class="my-full">
    <v-card class="tw-max-w-xl mx-auto my-auto">
      <v-alert
        v-if="validationFailure"
        transition="scroll-y-transition"
        type="error"
        >This is NOT a Kanoa URL!</v-alert
      >
      <v-card-title class="tw-text-center"> SCAN THE QR</v-card-title>
      <v-card-text class="mx-0 my-0">
        <qrcode-stream :camera="camera" @decode="onDecode" @init="onInit">
          <v-dialog
            v-model="validationPending"
            hide-overlay
            persistent
            width="300"
          >
            <v-card color="primary" dark>
              <v-card-text>
                Please wait
                <v-progress-linear
                  indeterminate
                  color="white"
                  class="mb-0"
                ></v-progress-linear>
              </v-card-text>
            </v-card>
          </v-dialog>
        </qrcode-stream>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      isValid: undefined,
      camera: "auto",
      result: null,
    };
  },

  mounted() {},

  computed: {
    validationPending() {
      return this.isValid === undefined && this.camera === "off";
    },

    validationSuccess() {
      return this.isValid === true;
    },

    validationFailure() {
      setTimeout(() => {
        this.resetValidationState();
        this.onInit(this.loading);
      }, 2000);
      return this.isValid === false;
    },
  },

  methods: {
    async onInit(promise) {
      try {
        await promise;
      } catch (e) {
        console.error(e);
      }
    },
    async loading() {
      return true;
    },
    resetValidationState() {
      this.isValid = undefined;
    },

    async onDecode(content) {
      this.result = content;
      this.turnCameraOff();

      // pretend it's taking really long
      await this.timeout(1000);

      content.startsWith("https://kanoa-app.com/#/place/") ||
      content.startsWith("https://kanoa-app.com/#/place/")
        ? this.redirectToShop(content)
        : (this.isValid = false);

      console.log(content);
      // some more delay, so users have time to read the message

      this.turnCameraOn();
    },

    turnCameraOn() {
      this.camera = "auto";
    },

    redirectToShop(link) {
      let l = document.createElement("a");
      l.href = link;

      const parts = link.split("/");
      const placeId = parts[parts.length - 2];
      const tableId = parts[parts.length - 1];
      this.$store.dispatch("addTableUser", tableId);
      this.$store.dispatch("addShopUser", placeId);

      this.$router.push(l.hash.substring(1));
    },

    turnCameraOff() {
      this.camera = "off";
    },

    timeout(ms) {
      return new Promise((resolve) => {
        window.setTimeout(resolve, ms);
      });
    },
  },
};
</script>
