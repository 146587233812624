<template>
  <v-container fluid class="pa-0">
    <swipe-list
      ref="list"
      class="card my-2"
      :disabled="!enabled"
      :items="liveOrders"
      item-key="orderInfo.id"
      :revealed.sync="revealed"
      :item-disabled="({ disabled }) => disabled"
      @closed="setLastEvent('closed', $event)"
      @leftRevealed="setLastEvent('leftRevealed', $event)"
      @rightRevealed="setLastEvent('rightRevealed', $event)"
    >
      <template v-slot="{ item, index }">
        <!-- item is the corresponding object from the array -->
        <!-- index is clearly the index -->
        <!-- revealLeft is method which toggles the left side -->
        <!-- revealRight is method which toggles the right side -->
        <!-- close is method which closes an opened side -->
        <div ref="content" class="card-content" @click="itemClick(item)">
          <!-- style content how ever you like -->

          <v-col cols="12" class="d-flex my-2">
            <v-row
              align="center"
              justify="center"
              class="px-3 bg-darke d-flex justify-space-between"
            >
              <v-col cols="2" class="d-felx d-xs-none d-md-block">
                <!-- <v-avatar size="100" tile class="d-flex d-md-block d-xs-none">
                  <v-img
                    :src="
                      item.user != null
                        ? 'https://kanoa-api.win' + item.user.profile_image
                        : 'test'
                    "
                  ></v-img>
                </v-avatar> -->

                {{ item.table }}
              </v-col>
              <v-col cols="2">
                <span class="text-h6" style="color: #79b7ff"> </span>
              </v-col>
              <v-col cols="5">
                <div
                  v-for="(itemDetails, index) in item.OrderDetails[
                    Object.keys(item.OrderDetails)
                  ]"
                  :key="index"
                  class="tw-block"
                >
                  <v-icon color="red">mdi-circle</v-icon>
                  <span class="text-h6" style="color: #79b7ff">
                    {{ itemDetails.product_name }} X {{ itemDetails.quantity }}
                  </span>
                </div>
              </v-col>

              <v-col cols="2" color="blue" class="text-start">
                <span>
                  <timeago
                    :datetime="item.orderInfo.created_at"
                    :auto-update="60"
                  ></timeago>
                </span>
              </v-col>
            </v-row>
          </v-col>
        </div>
      </template>
      <!-- left swipe side template and v-slot:left="{ item }" is the item clearly -->
      <!-- remove if you dont wanna have left swipe side  -->
      <template v-slot:left="{ item, close, index }">
        <div
          class="swipeout-action red"
          title="remove"
          @click="remove(item, index)"
        >
          <!-- place icon here or what ever you want -->
          <i class="fa fa-trash"></i>
        </div>
        <div class="swipeout-action purple" @click="close">
          <!-- place icon here or what ever you want -->
          <i class="fa fa-close"></i>
        </div>
      </template>
      <!-- right swipe side template and v-slot:right"{ item }" is the item clearly -->
      <!-- remove if you dont wanna have right swipe side  -->
      <template v-slot:right="{ item, index }">
        <div class="swipeout-action blue" @click="nextPage(item, index)">
          <!-- place icon here or what ever you want -->
          <v-icon> mdi-chevron-right </v-icon>
        </div>
      </template>
      <template v-slot:empty>
        <div>
          <!-- change liveOrders to an empty array to see this slot in action  -->
          list is empty ( filtered or just empty )
        </div>
      </template>
    </swipe-list>
    <p>
      <small>
        <i>Press and hold [shift] to select text</i>
      </small>
    </p>
  </v-container>
</template>

<script>
import { SwipeList, SwipeOut } from "vue-swipe-actions";
// eslint-disable-next-line no-unused-vars
import css from "vue-swipe-actions/dist/vue-swipe-actions.css";

export default {
  props: ["change"],
  components: {
    SwipeOut,
    SwipeList,
  },
  data() {
    return {
      enabled: true,
      page: 0,
      revealed: {},
      lastEventDescription: "",
      liveOrders: [],
    };
  },
  mounted() {
    window.Echo.channel(`shop_${this.$store.state.userId}`).listen(
      ".CreateOrder",
      (e) => {
        // if (e.test)
        // this.$router.push('/events/1/orderdone/12')
        this.$axios
          .get(`/shoporders/preparing/${this.$store.state.userId}`)
          .then((response) => {
            this.liveOrders = response.data;
          });
      }
    );
    // ideally should be in some global handler/store
    this.$axios
      .get(`/shoporders/preparing/${this.$store.state.userId}`)
      .then((response) => {
        this.liveOrders = response.data;
      });
    window.addEventListener("keydown", this.onKeyDown);
    window.addEventListener("keyup", this.onKeyUp);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.onKeyDown);
    window.removeEventListener("keyup", this.onKeyUp);
  },
  watch: {
    change: function (newVal) {
      if (newVal.title == "À PRÉPARER") {
        this.$axios
          .get(`/shoporders/preparing/${this.$store.state.userId}`)
          .then((response) => {
            this.liveOrders = response.data;
            console.log(newVal.title); // watch it
          });
      }
    },
  },
  methods: {
    nextPage(item, index) {
      let id = item.orderInfo.id;
      this.$axios
        .post("/updateorder/" + id, { status: "waiting" })
        .then((response) => {
          if (response.data.status)
            this.liveOrders = this.liveOrders.filter((i) => i !== item);
        });
    },
    dd(v) {
      console.log(v.target);
    },
    revealFirstRight() {
      this.$refs.list.revealRight(0);
    },
    revealFirstLeft() {
      this.$refs.list.revealLeft(0);
    },
    closeFirst() {
      this.$refs.list.closeActions(0);
    },
    closeAll() {
      this.$refs.list.closeActions();
    },
    remove(item, index) {
      console.log(item);

      this.$axios.delete(`/order/delete/${item.orderInfo.id}`).then((res) => {
        console.log(res);
      });
      this.liveOrders = this.liveOrders.filter((i) => i !== item);
    },

    setLastEvent(name, { item, index }) {
      this.lastEventDescription = {
        name,
        index,
        id: item.id,
      };
    },

    itemClick(e) {
      console.log(e, "item click");
    },
    fbClick(e) {
      console.log(e, "First Button Click");
    },
    sbClick(e) {
      console.log(e, "Second Button Click");
    },
    // keyboard
    onKeyDown(e) {
      if (e.keyCode !== 16) return;
      this.enabled = false;
    },
    onKeyUp(e) {
      if (e.keyCode !== 16) return;
      this.enabled = true;
    },
  },
};
</script>
<style>
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");

/* app specific styles */

.swipeout-action {
  display: flex;
  align-items: center;
  padding: 0 3rem;
  cursor: pointer;
  left: 0;
}
/* https://developer.apple.com/design/human-interface-guidelines/ios/visual-design/color/ */
.swipeout-action.blue {
  color: white;
  background-color: rgb(0, 122, 255);
}
.swipeout-action.blue:hover {
  background-color: darken(rgb(0, 122, 255), 5%);
}
.swipeout-action.purple {
  color: white;
  background-color: rgb(88, 86, 214);
}
.swipeout-action.purple:hover {
  background-color: darken(rgb(88, 86, 214), 5%);
}

.swipeout-action.red {
  color: white;
  background-color: rgb(255, 59, 48);
}
.swipeout-action.red:hover {
  background-color: darken(rgb(255, 59, 48), 5%);
}
.swipeout-action.green {
  color: white;
  background-color: rgb(76, 217, 100);
}
.swipeout-action.green:hover {
  background-color: darken(rgb(76, 217, 100), 5%);
}

.swipeout-list-item {
  flex: 1;
  border-bottom: 1px solid lightgray;
}

.swipeout-list-item:last-of-type {
  border-bottom: none;
}

.card {
  width: 100%;
  background-color: #0d131c;
  border-radius: 3px;
  box-shadow: none;
}
.card-content {
  /* padding: 1rem; */
}
.transition-right {
  transform: translate3d(100%, 0, 0) !important;
}
.transition-left {
  transform: translate3d(-100%, 0, 0) !important;
}

.toolbar {
  display: flex;
  align-items: center;
}

.toolbar .toolbar-section {
  flex: 0 0 auto;
}

.toolbar .toolbar-section--center {
  flex: 1000 1 0%;
}

.bg-darke {
  background-color: #0d131c;
}

.bg-lighten {
  background-color: #1d2634;
}

.center-text-orders {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
</style>
