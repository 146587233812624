<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title class="bg-titles">
            <div>Shop info</div>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <validation-observer ref="observer" v-slot="{ invalid }">
              <form @submit.prevent="submit" class="mx-4 my-2">
                <!-- NAme restau -->
                <validation-provider v-slot="{ errors }" name="Restaurant Name">
                  <v-label class="mb-2">Shop Name</v-label>
                  <v-text-field
                    class="mt-2"
                    outlined
                    v-model="restaurantName"
                    :error-messages="errors"
                    label="Restaurant Name"
                    required
                  ></v-text-field>
                </validation-provider>

                <!-- restau slug -->

                <!-- <validation-provider v-slot="{ errors }" name="Restaurant slug">
                  <v-label class="mb-2">Shop slug</v-label>
                  <v-text-field
                    class="mt-2"
                    v-model="restaurantSlug"
                    outlined
                    :error-messages="errors"
                    label="Restaurant slug"
                    required
                  ></v-text-field>
                </validation-provider> -->

                <!-- sous titre du restau -->

                <validation-provider
                  v-slot="{ errors }"
                  name="Restaurant sous titre"
                >
                  <v-label class="mb-2">Shop Sous titre</v-label>
                  <v-text-field
                    class="mt-2"
                    v-model="restaurantSousTitre"
                    outlined
                    :error-messages="errors"
                    label="Restaurant sous titre"
                    required
                  ></v-text-field>
                </validation-provider>

                <!-- horaire du restau -->

                <v-label class="">Horaire du Shop</v-label>
                <v-col cols="12" sm="8">
                  <el-time-picker
                    arrow-control
                    v-model="restauOpen"
                    placeholder="Overture"
                    value-format="HH:MM:SS"
                    class="mx-2"
                  >
                  </el-time-picker>
                  <el-time-picker
                    arrow-control
                    class="ml-2"
                    v-model="restauClose"
                    placeholder="Fermeture"
                  >
                  </el-time-picker>
                </v-col>

                <!-- description -->
                <v-col cols="12">
                  <v-label class="mb-2">Coleur du Theme</v-label>
                  <br />
                  <el-color-picker
                    class="mt-2"
                    v-model="color1"
                  ></el-color-picker>
                </v-col>
                <br />
                <v-label class="mb-2">Description du Shop</v-label>
                <v-textarea
                  v-model="bio"
                  auto-grow
                  outlined
                  class="mt-2"
                  color="deep-purple"
                  label="Description"
                  rows="8"
                ></v-textarea>

                <!-- restau phone number -->
                <validation-provider
                  v-slot="{ errors }"
                  name="phoneNumber"
                  :rules="{
                    required: true,
                    digits: 7,
                    regex: '^(71|72|74|76|81|82|84|85|86|87|88|89)\\d{5}$',
                  }"
                >
                  <v-label class="mb-2">Telephone du Shop</v-label>
                  <v-text-field
                    class="mt-2"
                    outlined
                    v-model="phoneNumber"
                    :counter="7"
                    :error-messages="errors"
                    label="Phone Number"
                    required
                    type="number"
                  ></v-text-field>
                </validation-provider>

                <!-- Emplacement du restaurant -->
                <v-label>Shop Address</v-label>
                <v-text-field
                  class="mt-2"
                  outlined
                  v-model="restauAddress"
                  label="Emplacement du restaurant"
                ></v-text-field>

                <!-- Autoriser la commande sur table -->
                <v-label class="mb-2">Sur table</v-label>
                <v-select
                  outlined
                  class="mt-2"
                  v-model="surtable"
                  :items="items"
                  label="Autoriser la commande sur table"
                  data-vv-name="select"
                  required
                ></v-select>

                <!-- Autoriser la commande emporter -->
                <v-label class="mb-2">Emporter</v-label>
                <v-select
                  class="mt-2"
                  outlined
                  v-model="emporter"
                  :items="items"
                  label="Autoriser la commande emporter"
                  data-vv-name="select"
                  required
                ></v-select>

                <!-- Autoriser la commande livraison -->
                <v-label class="mb-2">Livraison</v-label>
                <v-select
                  class="mt-2"
                  outlined
                  elevation-5
                  v-model="livraison"
                  :items="items"
                  label="Autoriser la commande livraison"
                  data-vv-name="select"
                  required
                ></v-select>
              </form>
            </validation-observer>
          </v-card-text>
          <v-card-actions>
            <v-btn class="mr-4" type="submit" color="primary" @click="submit">
              submit
            </v-btn>
            <v-btn @click="clear" color="red"> clear </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { required, digits, email, max, regex } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";

setInteractionMode("eager");

extend("digits", {
  ...digits,
  message: "{_field_} needs to be {length} digits. ({_value_})",
});

extend("required", {
  ...required,
  message: "{_field_} can not be empty",
});

extend("max", {
  ...max,
  message: "{_field_} may not be greater than {length} characters",
});

extend("regex", {
  ...regex,
  message: "{_field_} {_value_} does not match {regex}",
});

extend("email", {
  ...email,
  message: "Email must be valid",
});

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data: () => ({
    restaurantName: "",
    color1: "#409EFF",
    restaurantSousTitre: "",
    restauClose: "",
    restauOpen: "",
    surtable: "",
    emporter: "",
    livraison: "",
    restauAddress: "",
    phoneNumber: "",
    email: "",
    bio: "",
    select: null,
    items: ["oui", "non"],
    checkbox: null,
  }),
  created() {
    this.$axios.get("shop/info").then((res) => {
      console.log(res.data);
      this.bio = res.data.shop.description;
      this.restaurantName = res.data.shop.name;
      this.restauAddress = res.data.shop.address;
    });
  },

  methods: {
    submit() {
      console.log(this.restauOpen);
    },
    clear() {
      this.name = "";
      this.phoneNumber = "";
      this.email = "";
      this.select = null;
      this.checkbox = null;
      this.$refs.observer.reset();
    },
  },
};
</script>

<style scoped>
.bg-titles {
  background-color: #29374e;
}
</style>
