<template>
    <v-card
    class="mx-auto"
    :color="cardColor"
    max-width="344"
    outlined
  >
    <v-list-item three-line>
      <v-list-item-content>
        <div class="text-overline mb-4">
          {{ cardTitle}}
        </div>
        <v-list-item-title class="text-h5 mb-1">
            {{ cardData }}
        </v-list-item-title>
      </v-list-item-content>

      <v-icon x-large>{{ ButtonIcon }}</v-icon>
    </v-list-item>
  </v-card>
</template>


<script>
  export default {
    props: ['cardTitle' , 'cardData' , 'cardColor' , 'ButtonIcon' , "classe" , 'Members' , 'btncolor'],
  }
</script>