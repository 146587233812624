<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card color="#0D131C">
          <v-list three-line color="#0D131C">
            <template v-for="order in orders">
              <v-subheader
                v-if="order.header"
                :key="order.orderInfo.id + Math.random()"
                class="text-h6"
              >
                {{ order.header }}</v-subheader
              >

              <v-divider
                v-else-if="order.divider"
                :key="order.orderInfo.id + Math.random()"
                :inset="order.inset"
              ></v-divider>

              <v-list-item v-else :key="order.orderInfo.id + Math.random()">
                <v-list-item-avatar size="100" tile class="rounded-lg">
                  <v-img :src="order.shop.picture"></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title
                    v-html="order.shop.name"
                  ></v-list-item-title>
                  <v-list-item-subtitle
                    v-for="itemDetails in order.OrderDetails[
                      Object.keys(order.OrderDetails)
                    ]"
                    :key="itemDetails.id + Math.random()"
                  >
                    <span class="subtitle2">
                      {{ itemDetails.product_name }} X
                      {{ itemDetails.quantity }}
                    </span>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>
                    <span class="subtitle1 tw-text-xl tw-font-black">
                      {{ order.orderInfo.amount / 100 }} DH
                    </span>
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn
                    disabled
                    color="#0063FF"
                    class="btn-bottom rounded-lg"
                    @click="redoOrder(order.orderInfo.id)"
                  >
                    <v-icon> mdi-reload </v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    orders: {},
  }),
  mounted() {
    this.$axios.get("/userorders").then((res) => {
      this.orders = res.data;
      console.log(this.orders);
    });
  },
  // computed: {
  //   orders() {
  //     return this.$store.state.userOrders;
  //   },
  // },
  methods: {
    redoOrder(id) {
      console.log(id);
    },
  },
};
</script>

<style scoped>
.subtitle1 {
  color: #79a4e8;
}
.subtitle2 {
  color: #889cbd;
}

.btn-bottom {
  position: absolute;
  bottom: 30%;
  right: 8px;
}
</style>
