<template>
  <v-container>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-row justify="center">
      <v-col cols="11" class="mt-4">
        <v-btn
          color="primary"
          dark
          @click="
            dialog = true;
            catDialog = 1;
          "
        >
          Ajouter categorie
        </v-btn>
      </v-col>
      <!-- Delete Dialog -->
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="text-h5"
            >Are you sure you want to delete it?</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="deleteConfirm = false"
              >Cancel</v-btn
            >
            <v-btn color="blue darken-1" text @click="deleteConfirm = true"
              >OK</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- add categorie dialog -->
      <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="text-h5">Categorie</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    outlined
                    v-model="categorieName"
                    label="Nom du categore"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="
                dialog = false;
                categorieName = null;
              "
            >
              Annuler
            </v-btn>
            <v-btn color="blue darken-1" text @click="addCategory()">
              {{ catDialog == 1 ? "Ajouter" : "Modifier" }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Dialog sous categorie -->

      <v-dialog v-model="dialogSous" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ addNewElem }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    outlined
                    v-model="sousCategory"
                    label="Nom du categorie"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="
                dialogSous = false;
                sousCategory = null;
              "
            >
              Close
            </v-btn>
            <v-btn color="blue darken-1" text @click="pushItem()">
              {{ souscatDialog == 1 ? "Ajouter" : "Modifier" }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- dialog product -->

      <v-dialog v-model="product" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ addNewElem }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <div
                    class="tw-w-full mx-auto my-3 tw-content-center tw-flex tw-justify-center tw-products-center"
                  >
                    <el-upload
                      :before-upload="beforeImage"
                      :http-request="handleUploadAvatar"
                      ref="upload"
                      class="avatar-uploader"
                      :show-file-list="false"
                      :on-success="handleAvatarSuccess"
                      action="#"
                    >
                      <!-- <v-avatar v-if="imageUrl" size="100"> -->
                      <img
                        v-if="dialogProduct.productimageUrl"
                        :src="
                          'https://kanoa-api.win' +
                          dialogProduct.productimageUrl
                        "
                        class="rounded-xl avatar"
                      />
                      <!-- </v-avatar> -->
                      <!-- <v-avatar  size="100"> -->
                      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      <!-- </v-avatar> -->
                    </el-upload>
                  </div>
                  <v-text-field
                    outlined
                    v-model="dialogProduct.name"
                    label="Nom du produit"
                  ></v-text-field>
                  <v-text-field
                    outlined
                    v-model="dialogProduct.price"
                    label="Prix du produit"
                  ></v-text-field>
                  <div>
                    <el-switch
                      v-model="dialogProduct.availble"
                      active-color="#13ce66"
                      inactive-color="#ff4949"
                      class="tw-mx-2"
                    >
                    </el-switch>
                    <span class="tw-text-lg"
                      >{{
                        dialogProduct.availble == "1" ? "Dispo" : "Non Dispo"
                      }}
                    </span>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeModalProduct()">
              Close
            </v-btn>
            <v-btn color="blue darken-1" text @click="pushItem()">
              {{ editProduct ? "Modifier" : "Ajouter" }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- end of dialog product  -->
    </v-row>

    <v-row justify="start" class="mx-2">
      <div
        class="my-2 rounded-lg tw-block tw-w-full pa-2 bg-section"
        v-for="categorie in categories"
        :key="categorie.CategorieID"
      >
        <div class="rounded-lg tw-flex tw-justify-end my-2">
          <span
            class="d-inline tw-text-3xl tw-w-full mx-2 my-auto"
            align="start"
            justify="start"
          >
            {{ categorie.name }}
          </span>
          <v-col cols="8" class="d-inline" align="end" justify="end">
            <!-- <v-btn
              class="mx-1 white--text"
              color="#40a9ff"
              small
              @click="addSousCategory(categorie)"
              >add categorie</v-btn
            > -->
            <v-btn
              class="mx-1 white--text"
              color="#40a9ff"
              small
              @click="addItem(null, categorie)"
              >add item</v-btn
            >
            <v-btn
              class="mx-1 white--text"
              color="#648814"
              small
              @click="modifyCat(categorie)"
              >modif</v-btn
            >
            <v-btn
              class="mx-1 white--text"
              color="red"
              small
              @click="deleteCategory(categorie)"
              >delete</v-btn
            >
          </v-col>
        </div>
        <v-divider class="my-2"></v-divider>
        <div class="">
          <v-row class="d-flex justify-start">
            <v-col
              v-for="(item, i) in categorie.products"
              :key="i"
              cols="4"
              class="my-4"
            >
              <v-hover v-slot="{ hover }">
                <div class="tw-block tw-overflow-hidden tw-rounded-lg">
                  <template>
                    <div style="position: relative" class="tw-bg-gray-600">
                      <v-img
                        contain
                        class="tw-object-cover tw-w-full tw-h-56"
                        :src="item.picture"
                        alt=""
                      >
                        <v-expand-transition>
                          <div
                            v-if="hover"
                            align="center"
                            justify="center"
                            style="background: rgb(0, 0, 0, 0.5)"
                            class="text-center d-flex justify-center transition-fast-in-fast-out v-card-reveal tw-w-full tw-h-full"
                          >
                            <div
                              align="center"
                              justify="center"
                              class="tw-border-solid tw-rounded-full tw-border-black my-auto d-flex justify-center"
                            >
                              <div
                                class="tw-border-solid tw-rounded-full tw-border-black"
                              >
                                <v-btn
                                  icon
                                  align="center"
                                  justify="center"
                                  class="mx-2 transition-fast-in-fast-out"
                                  @click="
                                    editProduct = true;
                                    productEditDialog(item);
                                  "
                                >
                                  <v-icon large color="blue" x-large
                                    >mdi-pencil-circle-outline</v-icon
                                  >
                                </v-btn>
                              </div>
                              <v-btn
                                icon
                                align="center"
                                justify="center"
                                class="mx-2 transition-fast-in-fast-out"
                                @click="deleteProduct(item, categorie)"
                              >
                                <v-icon color="red" x-large
                                  >mdi-delete-circle-outline</v-icon
                                >
                              </v-btn>
                            </div>
                            <!-- <v-btn @click="productEditDialog(item)">
                            <v-icon>mdi-trash</v-icon>
                          </v-btn> -->
                          </div>
                        </v-expand-transition>
                      </v-img>
                    </div>
                  </template>
                  <div class="tw-p-2 tw-bg-gray-900">
                    <strong
                      class="my-4 tw-border tw-border-current tw-px-5 tw-py-1 tw-rounded-full tw-text-sm"
                      :class="checkProductAvailble(item)"
                      align="start"
                      justify="start"
                    >
                      {{ item.availble ? "available" : "non available" }}
                    </strong>
                    <h5
                      class="tw-text-sm my-4 mx-2 tw-text-center tw-text-white my-2"
                    >
                      {{ item.name }}
                    </h5>

                    <strong
                      class="tw-block mt-4 my-1 border-price tw-text-gray-300 tw-rounded-sm tw-px-5 tw-pt-1 tw-text-lg"
                      align="center"
                      justify="center"
                    >
                      {{ item.price }} $
                    </strong>
                  </div>
                </div>
              </v-hover>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import draggable from "vuedraggable";

export default {
  components: {
    draggable,
  },
  data: () => ({
    loading: false,
    dialogDelete: false,
    categorieTem: null,
    sousCategorieTem: null,
    prodTem: null,
    souscatDialog: null,
    editProduct: false,
    deleteItem: null,
    dialog: false,
    hover: false,
    loading: false,
    noEditProd: null,
    product: false,
    catDialog: null,
    addNewElem: "",
    dialogSous: false,
    type: "",
    sousCategory: "",
    categorieID: null,
    categorieName: "",
    dialogProduct: {
      id: null,
      name: null,
      category_id: null,
      price: null,
      size: null,
      brand_id: null,
      picture: "https://www.brother.ca/resources/images/no-product-image.png",
      shop_id: null,
      description: null,
      SousCategorieId: null,
      availble: null,
    },
    dialogProductDefault: {
      id: null,
      name: null,
      category_id: null,
      price: null,
      size: null,
      brand_id: null,
      picture: "https://www.brother.ca/resources/images/no-product-image.png",
      shop_id: null,
      description: null,
      SousCategorieId: null,
      availble: null,
    },
    categories: [],
  }),
  mounted() {
    this.loading = true;

    this.getProducts();
  },
  methods: {
    checkProductAvailble(prod) {
      return parseInt(prod.availble) ? "text-success" : "text-fail";
    },
    closeModalProduct() {
      this.editProduct = false;
      this.product = false;
      this.dialogProduct = Object.assign({}, this.dialogProductDefault);
    },
    modifySousCat(sousCat, cat) {
      this.dialogSous = true;
      this.type = "cat";
      this.souscatDialog = 2;
      this.sousCategory = sousCat.name;
      this.sousCategorieTem = sousCat;
      this.categorieTem = cat;
    },
    modifyCat(cat) {
      this.dialog = true;
      this.catDialog = 2;
      this.categorieName = cat.name;
      this.categorieTem = cat;
    },
    onClick() {
      /*this will toggle only by icon click. at the same time, will prevent toggle 
      by clicking on header. */

      const curr = this.panel;
      this.panel = curr === undefined ? 0 : undefined;
    },
    deleteItemFun(catgorie, id) {
      this.deleteSousCategory(id, catgorie);
    },
    addCategory() {
      this.loading = true;

      if (this.catDialog == 1) {
        this.$axios
          .post("/categorie", { name: this.categorieName })
          .then((res) => {
            console.log(res.data);
            this.categories.push({
              CategorieID: res.data.categorie.id,
              name: res.data.categorie.name,
              souscategories: [],
              products: [],
            });
            this.$message({
              showClose: true,
              message: res.data.message,
              type: "success",
            });
            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
          });
      } else if (this.catDialog == 2) {
        this.$axios
          .put("/categorie/" + this.categorieTem.CategorieID, {
            name: this.categorieName,
          })
          .then((res) => {
            console.log(res.data);
            this.$message({
              showClose: true,
              message: res.data.message,
              type: "success",
            });
            this.categories[this.categories.indexOf(this.categorieTem)].name =
              res.data.categoriename;
            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
          });
      }

      this.dialog = false;
      this.categorieName = "";
    },
    deleteCategory(categorie) {
      this.loading = true;
      this.$axios
        .delete("/categorie/" + categorie.CategorieID)
        .then((res) => {
          this.categories.splice(this.categories.indexOf(categorie), 1);
          this.loading = false;
          this.$message({
            showClose: true,
            message: res.data.message,
            type: "success",
          });
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    deleteSousCategory(sousCategory, category) {
      this.loading = true;
      this.$axios
        .delete("/souscategorie/" + sousCategory.id)
        .then((res) => {
          category.souscategories.splice(
            category.souscategories.indexOf(sousCategory),
            1
          );
          this.loading = false;
          this.$message({
            showClose: true,
            message: res.data.message,
            type: "success",
          });
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    addSousCategory(categorie) {
      this.addNewElem = "Add Categorie";
      (this.dialogSous = true), (this.type = "cat");
      this.categorieID = categorie;
      this.souscatDialog = 1;
    },
    addItem(soucategorie, category) {
      this.addNewElem = "Add Item";
      (this.product = true), (this.type = "item");
      this.dialogProduct = Object.assign({}, this.dialogProductDefault);
      this.categorieTem = category;
      this.sousCategorieTem = soucategorie;
    },

    pushItem() {
      this.loading = true;
      if (this.type == "cat") {
        if (this.souscatDialog == 1) {
          let categorie = this.categorieID;
          this.$axios
            .post("/souscategorie", {
              category_id: categorie.CategorieID,
              name: this.sousCategory,
            })
            .then((res) => {
              console.log(res.data);
              this.$message({
                showClose: true,
                message: res.data.message,
                type: "success",
              });
              this.pushSousCategory(res.data.categories, categorie);
              this.loading = false;
            })
            .catch((error) => {
              console.log(error);
              this.loading = false;
            });
        } else {
          console.log(
            "this is the new name of this sous categorie",
            this.sousCategory
          );
          this.$axios
            .put("/souscategorie/" + this.sousCategorieTem.id, {
              name: this.sousCategory,
            })
            .then((res) => {
              this.sousCategorieTem.name = res.data.souscategoriename;
              this.$message({
                showClose: true,
                message: res.data.message,
                type: "success",
              });
              this.loading = false;
            });
        }

        // this.categories[this.categorieID].SousCategorie.name = 'test'
      } else if (this.type == "item") {
        // console.log(this.categorieTem);

        if (!this.editProduct) {
          this.dialogProduct["category_id"] = this.categorieTem.CategorieID;
          if (this.sousCategorieTem)
            this.dialogProduct["SousCategorieId"] = this.sousCategorieTem.id;
          else this.dialogProduct["SousCategorieId"] = 0;

          this.$axios
            .post("/product", this.dialogProduct)
            .then((res) => {
              console.log(res.data);
            })
            .catch((error) => {
              console.log(error);
            });

          if (!this.sousCategorieTem) {
            this.categorieTem.products.push(this.dialogProduct);
          } else {
            this.sousCategorieTem.products.push(this.dialogProduct);
          }
          this.closeModalProduct();
          // this.categories[this.categoriesindexOf(this.categorieID)].products.push(this.sousCategory);
          this.loading = false;
          // console.log(this.dialogProduct);
        } else {
          let prod = this.dialogProduct;
          this.$axios
            .put("/product/" + this.dialogProduct.id, this.dialogProduct)
            .then((res) => {
              this.getProducts();
            })
            .catch((err) => {
              console.log(err);
            });
          this.loading = false;
          this.closeModalProduct();
        }
      }
      this.categorieID = null;
      this.type = "";
      this.dialogSous = false;
      this.sousCategory = "";
    },
    getProducts() {
      this.$axios.get(`/products/${this.$store.state.userId}`).then((res) => {
        this.loading = false;
        this.categories = res.data.products;
      });
    },
    pushSousCategory(sousCategory, category) {
      category.souscategories.push(sousCategory);
    },
    async handleUploadAvatar(params) {
      this.loading = true;
      let file = params.file;
      let urrrl = URL.createObjectURL(file);
      this.dialogProduct.productimageUrl = urrrl;
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = function () {
        this.dialogProduct.productImage = reader.result;
        this.loading = false;
        return reader.result;
      }.bind(this);
    },

    handleBackgroundSuccess(res, file) {},
    handleAvatarSuccess(res, file) {},
    beforeImage(file) {
      console.log(file.type);
      const isJPEG = file.type == "image/jpeg";
      const isJPG = file.type == "image/jpg";
      const isPNG = file.type == "image/png";
      const isWEBP = file.type == "image/webp";
      const isGIF = file.type == "image/gif";
      const isSVG = file.type == "image/svg+xml";
      const isLt2M = file.size / 1024 / 1024 < 3.5;

      if (!isPNG && !isJPEG && !isJPG && !isSVG && !isWEBP && !isGIF) {
        this.$message.error("Avatar picture must be an IMAGE!");
      }
      if (!isLt2M) {
        this.$message.error("Avatar picture size can not exceed 3,5MB!");
      }
      return isPNG || isJPEG || isJPG || isSVG || isWEBP || (isGIF && isLt2M);
    },
    productEditDialog(product) {
      // this.dialogProductt.productname =
      this.noEditProd = product;
      this.productTem = { ...product };
      this.dialogProduct = { ...product };
      this.dialogProduct.productimageUrl = this.productTem.picture;
      this.dialogProduct.availble = parseInt(this.productTem.availble)
        ? true
        : false;
      this.product = true;
      this.type = "item";
    },
    deleteProduct(item, cat) {
      this.loading = true;

      this.$axios
        .delete("/product/" + item.id)
        .then((res) => {
          this.$message({
            showClose: true,
            message: res.data.message,
            type: "success",
          });
          cat.products.splice(cat.products.indexOf(item), 1);
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
  },
};
</script>

<style scoper>
.header-bg {
  background-color: #3b4d69;
}

.bg-section {
  background-color: #5c4ef24a;
}

.border-price {
  border-top: 3px solid rgb(100, 97, 97);
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.text-success {
  color: rgb(13, 198, 13);
}

.text-fail {
  color: red;
}
</style>
