<template>
  <v-container>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <h1 class="text-center text-h4">C’est prêt !</h1>
      </v-col>
      <v-col cols="12" align="center" justify="center">
        <v-avatar size="200" class="my-auto">
          <v-img :src="'https://kanoa-api.win' + profileImage"> </v-img>
        </v-avatar>
        <h1 class="text-h5 tw-text-blue-400 tw-text-extrabold">
          {{ name }}
        </h1>
      </v-col>
      <v-col cols="12" align="center" justify="center">
        <v-avatar size="200">
          <v-img src="https://i.ibb.co/wMNd3nQ/Ellipse-2.png"> </v-img>
        </v-avatar>
      </v-col>

      <v-col cols="12" align="center" justify="center">
        <v-col cols="12" lg="6" md="8" sm="10" align="start" justify="start">
          <v-card
            color="#1D2634"
            class="tw-rounded-xl tw-border-gray-50"
            flat
            outlined
          >
            <h1 class="tw-text-lg tw-text-gray-400 mb-4 text-center mt-3">
              Commande n°
            </h1>
            <h1 class="tw-text-xl text-color mb-4 text-center">
              {{ order_ref }}
            </h1>
            <v-list-item v-for="item in products" :key="item.id" class="my-2">
              <v-list-item-content>
                <v-list-item-title class="mx-3 tw-text-xl tw-font-extrabold">
                  <span class="tw-text-gray-400">{{ item.quantity }} x </span
                  >{{ item.name }}</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
      </v-col>
      <v-col cols="12" align="center" justify="center">
        <v-col cols="12" lg="6" md="8" sm="10">
          <v-card color="#1D2634" class="tw-rounded-lg pa-2" flat outlined>
            <span class="text-color tw-block text-center"> Total </span>
            <span class="text-h4 tw-font-bold tw-block text-center">
              {{ (amount / 100).toFixed(2) }} $
            </span>
          </v-card>
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    orderInfo: null,
    userInfo: null,
    products: null,
    loading: false,
    order_ref: null,
    amount: null,
    name: null,
    profileImage: null,
  }),
  created() {
    this.loading = true;
    this.$axios
      .get("/order/" + this.$route.params.id)
      .then((res) => {
        this.userInfo = res.data.user;
        this.orderInfo = res.data.orderInfo;
        this.products = res.data.products;
        this.name = this.userInfo.first_name + " " + this.userInfo.last_name;
        this.profileImage = this.userInfo.profile_image;
        this.order_ref = this.orderInfo.order_ref;
        this.amount = this.orderInfo.amount;
        this.loading = false;
      })
      .catch((e) => {
        console.log(e);
        this.loading = false;
      });
  },
};
</script>

<style scoped>
.span-text {
  color: #79b7ff;
}
</style>
